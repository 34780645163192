import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/combinedReducers";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AlertContent from "./AlertContent";

const useStyles = makeStyles((theme: Theme) => ({
    snackbar: {
        background: theme.palette.primary.main,
    },
}));

const ServiceWorkerAlert = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const isServiceWorkerUpdated = useSelector(
        (state: RootState) => state.serviceWorkerReducer.serviceWorkerUpdated
    );

    const serviceWorkerRegistration = useSelector(
        (state: RootState) =>
            state.serviceWorkerReducer.serviceWorkerRegistration
    );

    const updateServiceWorker = () => {
        const registrationWaiting = serviceWorkerRegistration.waiting;
        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: "SKIP_WAITING" });

            registrationWaiting.addEventListener("statechange", (e: any) => {
                if (e.target.state === "activated") {
                    window.location.reload();
                }
            });
        } else {
            window.location.reload();
        }
    };

    const handleClose = () => {
        dispatch({ type: "SW_UPDATE", payload: {} });
    };

    return (
        <div>
            {isServiceWorkerUpdated && (
                <Snackbar
                    className={classes.snackbar}
                    message="En ny oppdatering er tilgjengelig. Du blir ikke logget ut."
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={isServiceWorkerUpdated}
                    onClose={handleClose}
                    action={
                        <AlertContent
                            handleClose={handleClose}
                            updateServiceWorker={updateServiceWorker}
                        />
                    }
                />
            )}
        </div>
    );
};
export default ServiceWorkerAlert;
