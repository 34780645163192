import React from "react";
import { useTheme } from "@material-ui/core/styles";
import DesktopView from "./desktop/DesktopView";
import MobileView from "./mobile/MobileView";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Settings: React.FC = () => {
    const theme = useTheme();
    const isOnMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

    return <>{isOnMobileDevice ? <MobileView /> : <DesktopView />}</>;
};

export default Settings;
