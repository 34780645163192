import React from "react";
import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./snackbarStyle.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";

const defaultProps: ToastOptions = {
    position: "bottom-center",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
};

interface Props {
    message: string;
    type: string;
}

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    icon: {
        marginRight: "1rem",
    },
}));

const SnackbarLayout: React.FC<Props> = ({ message, type }) => {
    const classes = useStyles();
    const getIcon = () => {
        switch (type) {
            case "error":
                return (
                    <ErrorOutlineIcon
                        color="secondary"
                        className={classes.icon}
                    />
                );
            case "success":
                return (
                    <CheckCircleIcon
                        color="secondary"
                        className={classes.icon}
                    />
                );
            case "warning":
                return <WarningIcon color="primary" className={classes.icon} />;
            case "info":
                return <InfoIcon color="secondary" className={classes.icon} />;
            default:
                return <></>;
        }
    };

    return (
        <div className={classes.root}>
            {getIcon()} {message}
        </div>
    );
};

export const customToast = {
    success(message: string, options: ToastOptions = {}) {
        toast.success(<SnackbarLayout message={message} type="success" />, {
            ...options,
            ...defaultProps,
            autoClose: 3000,
            className: "snackbarSuccess",
        });
    },
    warning(message: string, options: ToastOptions = {}) {
        toast.warning(<SnackbarLayout message={message} type="warning" />, {
            ...options,
            ...defaultProps,
            autoClose: 5000,
            className: "snackbarWarning",
            closeButton: <CloseIcon fontSize="small" />,
        });
    },
    error(message: string, options: ToastOptions = {}) {
        toast.error(<SnackbarLayout message={message} type="error" />, {
            ...options,
            ...defaultProps,
            autoClose: false,
            className: "snackbarError",
        });
    },
    info(message: string, options: ToastOptions = {}) {
        toast.info(<SnackbarLayout message={message} type="info" />, {
            ...options,
            ...defaultProps,
            autoClose: 5000,
            className: "snackbarInfo",
        });
    },
};
