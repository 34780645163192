import {
    AccountState,
    AccountActionTypes,
    SET_ACCOUNT,
    SET_ACCOUNT_FAIL,
} from "./types";

export const initialState: AccountState = {
    account: undefined,
    error: null,
};

export const accountReducer = (
    state = initialState,
    action: AccountActionTypes
) => {
    switch (action.type) {
        case SET_ACCOUNT:
            return {
                ...state,
                account: action.payload,
            };
        case SET_ACCOUNT_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };

        default:
            return state;
    }
};
