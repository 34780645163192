import React, { useState } from "react";
import DialogBox from "../common/DialogBox";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AddUserDialogBoxContent from "./AddUserDialogBoxContent";
import { httpPost } from "../../services/http/httpService";
import { Role } from "./role";

const useStyles = makeStyles(() => ({
    button: {
        marginTop: "1rem",
    },
}));

interface Props {
    omradeId: string;
    leggTil: (data: any) => void;
    role: Role;
}

const AddUserDialogBox: React.FC<Props> = ({ omradeId, leggTil, role }) => {
    const classes = useStyles();
    const initialState = {
        id: 0,
        azureAdId: "",
        name: "",
        mobilePhone: "",
        email: "",
    };
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selected, setSelected] = useState(initialState);
    const [selectedId, setSelectedId] = useState("");

    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedId(event.target.value);
        setError(null);
    };
    const handleItemClick = (id: number, item: any) => {
        setSelectedId(id.toString());
        setSelected(item);
    };

    const clearSelected = () => {
        setSelected(initialState);
        setSelectedId("");
        setError(null);
    };

    const handleClose = () => {
        setOpen(false);
        setSelected(initialState);
        setSelectedId("");
        setError(null);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const area = role === Role.PREST ? "beredskapsområdet" : "bispedømmet";

    const handleLeggTil = async () => {
        setLoading(true);
        const areapath =
            role === Role.PREST ? "Beredskapsomrader" : "Bispedommer";
        try {
            const result = await httpPost(
                `api/${areapath}/${omradeId}/${role}`,
                {
                    azureAdId: selected.azureAdId,
                    id: parseInt(omradeId),
                }
            );
            leggTil(result);
            clearSelected();
            setOpen(false);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                startIcon={<AddIcon />}
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={handleOpen}
            >
                Legg Til {role}
            </Button>
            <DialogBox
                handleClick={handleLeggTil}
                title={`Legg til ${role} i ${area}`}
                buttonText="Legg til"
                open={open}
                handleClose={handleClose}
                loading={loading}
                disableButton={!selectedId}
                error={
                    error
                        ? `${selected.name} har allerede blitt lagt til i ${area}, og legges ikke til på nytt.`
                        : ""
                }
            >
                <Container>
                    <AddUserDialogBoxContent
                        role={role}
                        handleChangeRadio={handleChangeRadio}
                        selectedId={selectedId}
                        handleItemClick={handleItemClick}
                        selected={selected}
                        clearSelected={clearSelected}
                    />
                </Container>
            </DialogBox>
        </>
    );
};
export default AddUserDialogBox;
