import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitleMUI from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, Theme } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogBoxAlert from "./DialogBoxAlert";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: "1rem",
    },
    closeButton: {
        position: "absolute",
        right: "0.5rem",
        top: "0.5rem",
        color: "grey",
    },
    title: {
        marginLeft: "0.5rem",
    },
    error: {
        color: theme.palette.error.main,
    },
}));

interface Props {
    open: boolean;
    handleClose: () => void;
    children: React.ReactNode;
    title: string;
    buttonText?: string;
    cancelButtonText?: string;
    handleClick: () => void;
    loading?: boolean;
    disableButton?: boolean;
    error?: any;
}

interface DialogTitleProps {
    children: React.ReactNode;
    onClose: () => void;
}

export const DialogTitle: React.FC<DialogTitleProps> = ({
    children,
    onClose,
    ...other
}) => {
    const classes = useStyles();
    return (
        <DialogTitleMUI className={classes.root} {...other}>
            <div className={classes.title}>{children}</div>
            {onClose ? (
                <IconButton
                    id="closeDialog"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitleMUI>
    );
};

const DialogBox: React.FC<Props> = ({
    open,
    loading,
    handleClose,
    children,
    title,
    buttonText,
    cancelButtonText = "Avbryt",
    handleClick,
    disableButton,
    error,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dialogbox"
            fullWidth
        >
            <DialogTitle onClose={handleClose}>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            {error && <DialogBoxAlert feilmelding={error} />}
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                >
                    {cancelButtonText}
                </Button>
                {buttonText && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        disabled={loading || disableButton}
                    >
                        {buttonText}
                    </Button>
                )}
            </DialogActions>
            {loading && <LinearProgress />}
        </Dialog>
    );
};

export default DialogBox;
