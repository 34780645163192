import React from "react";
import ListMUI from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { User } from "../types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLoader } from "../customHooks/useLoader";
import { httpGet } from "../../services/http/httpService";
import { LinearProgress } from "@material-ui/core";
import InfoBox from "../common/InfoBox";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { feilmeldinger } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        marginLeft: "0.5rem",
    },
    toggleSwitch: {
        marginLeft: "auto",
    },
}));

interface Props {
    prost: User;
    bispedommeId: string;
    data: ProstVarselResponse[] | null;
    setData: (curr: any) => void;
}

export type ProstVarselResponse = {
    userId: number;
    beredskapsomradeId: number;
    beredskapsomradeName: string;
    varsel: boolean;
};

const ProstVarslingerListe: React.FC<Props> = ({
    bispedommeId,
    prost,
    data,
    setData,
}) => {
    const classes = useStyles();

    const theme = useTheme();
    const isOnMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    const label = (varsel: boolean) => {
        if (isOnMobileDevice) {
            return "";
        }
        return varsel ? "Varsling på" : "Varsling av";
    };

    const { loading, error } = useLoader(() =>
        httpGet(
            `api/Bispedommer/${bispedommeId}/proster/${prost.id}/varsel`
        ).then((res) => {
            setData(res);
        })
    );

    const handleToggle = (index: number) => {
        if (data && index < data.length) {
            const newData = [...data];
            newData[index].varsel = !data[index].varsel;
            setData(newData);
        }
    };

    return (
        <>
            {loading && <LinearProgress />}
            {error ? (
                <InfoBox
                    message={feilmeldinger.uventetFeilProvPaNytt}
                    icon={<ErrorOutlineIcon />}
                />
            ) : (
                <ListMUI>
                    {data?.map((area, i) => (
                        <ListItem disableGutters divider key={i}>
                            <Typography className={classes.header}>
                                {area.beredskapsomradeName}
                            </Typography>

                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={loading}
                                        color="primary"
                                        checked={area.varsel}
                                        onChange={() => handleToggle(i)}
                                        value={area.varsel}
                                    />
                                }
                                className={classes.toggleSwitch}
                                label={label(area.varsel)}
                                labelPlacement={"start"}
                            />
                        </ListItem>
                    ))}
                </ListMUI>
            )}
        </>
    );
};
export default ProstVarslingerListe;
