import { combineReducers, createStore, applyMiddleware } from "redux";
import { authReducer } from "./auth/authReducer";
import { accountReducer } from "./account/accountReducer";
import { presterReducer } from "./prester/presterReducer";
import { bispedommerReducer } from "./bispedommer/bispedommerReducer";
import { serviceWorkerReducer } from "./serviceWorker/serviceWorkerReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
    authReducer,
    accountReducer,
    presterReducer,
    serviceWorkerReducer,
    bispedommerReducer,
});

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
