import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "../common/Card";
import BeredskapsomradeTabell from "./BeredskapsomradeTabell";
import AddUserDialogBox from "../leggTilBruker/AddUserDialogBox";
import { visKommuner } from "../common/VisKommuner";
import { useParams } from "react-router-dom";
import { useLoader } from "../customHooks/useLoader";
import { httpGet } from "../../services/http/httpService";
import Breadcrumb from "../common/Breadcrumb";
import InfoBox from "../common/InfoBox";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Role } from "../leggTilBruker/role";
import {
    RoleContext,
    RoleContextType,
} from "../common/contexts/RoleContextProvider";
import {
    BeredskapsomradeContext,
    BeredskapsContextType,
} from "../beredskapsomrade/contexts/beredskapsomradeContext";
import { roles } from "../../utils/enum";
import TilbakeButton from "../common/TilbakeButton";
import Background from "../common/Background";
import HideForRole from "../common/HideForRole";
import PrestIBeredskap from "./prestIBeredskap";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { getBreadcrumbPropsByRole } from "./getBreadcrumbProps";
import ToggleNotification from "../notifications/ToggleNotification";
import NotificationStatus from "../notifications/NotificationStatus";
import { useNotification } from "../notifications/useNotification";
import UserContext, {
    UserContextType,
} from "../common/contexts/UserContextProvider";

const useStyles = makeStyles((theme: Theme) => ({
    topWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
    },
    mainTitle: {
        marginBottom: "1rem",
    },
    statusDiv: {
        marginTop: "2rem",
        marginBottom: "3rem",
    },
    button: {
        marginTop: "1rem",
    },

    link: { textDecoration: "none" },
    kommuner: {
        marginBottom: "3rem",
    },
    presterHeader: {
        marginTop: "4rem",
        [theme.breakpoints.down("sm")]: {
            marginTop: "2rem",
        },
        marginBottom: "1rem",
    },
}));

interface ParamTypes {
    beredskapsomrade_id: string;
    bispedomme_id: string;
}

const Beredskapsomrade: React.FC = () => {
    const classes = useStyles();

    const { user } = useContext(UserContext) as UserContextType;
    const { role } = useContext(RoleContext) as RoleContextType;

    const { data, setData, leggTilPrest, getPrestIBeredskap } = useContext(
        BeredskapsomradeContext
    ) as BeredskapsContextType;

    const { beredskapsomrade_id, bispedomme_id } = useParams<ParamTypes>();
    const { loading, error } = useLoader(() =>
        httpGet(`api/Beredskapsomrader/${beredskapsomrade_id}`).then(
            (res) => res && setData(res)
        )
    );

    const {
        notification,
        loadingNotification,
        handleToggleNotification,
    } = useNotification(
        beredskapsomrade_id,
        user,
        role,
        data?.beredskapsomrade.varsel
    );

    var prestIBeredskap = getPrestIBeredskap();
    var breadcrumbProps = getBreadcrumbPropsByRole(
        role,
        data.beredskapsomrade,
        bispedomme_id,
        beredskapsomrade_id
    );
    return (
        <Background>
            <Breadcrumb
                root={breadcrumbProps.root}
                paths={breadcrumbProps.paths}
            />

            <Card padding="1.5rem">
                <div className={classes.topWrapper}>
                    <TilbakeButton linkTo={`/bispedommer/${bispedomme_id}`} />

                    <HideForRole
                        roles={[roles.ADMIN, roles.BEREDSKAPSANSVARLIG]}
                    >
                        {/* Hide for berans aswell */}
                        {!loading && (
                            <ToggleNotification
                                loading={loadingNotification}
                                notification={notification}
                                handleClick={handleToggleNotification}
                            />
                        )}
                    </HideForRole>
                </div>
                {loading && <LinearProgress />}
                {error ? (
                    <InfoBox
                        message={error.message}
                        icon={<ErrorOutlineIcon />}
                    />
                ) : (
                    <>
                        <Typography variant="h4" className={classes.mainTitle}>
                            {data.beredskapsomrade.navn}
                        </Typography>
                        <div className={classes.kommuner}>
                            {visKommuner(
                                data.beredskapsomrade.kommuner,
                                data.beredskapsomrade.kommuner.length
                            )}
                        </div>

                        <div className={classes.statusDiv}>
                            <NotificationStatus notification={notification} />
                        </div>

                        {prestIBeredskap.map((prest) => (
                            <PrestIBeredskap prest={prest}></PrestIBeredskap>
                        ))}

                        <Typography
                            className={classes.presterHeader}
                            variant="h6"
                        >
                            Prester i beredskapsområdet:
                        </Typography>

                        <BeredskapsomradeTabell prester={data.prester} />

                        <HideForRole role={roles.PROST}>
                            <AddUserDialogBox
                                omradeId={beredskapsomrade_id}
                                leggTil={leggTilPrest}
                                role={Role.PREST}
                            />
                        </HideForRole>
                    </>
                )}
            </Card>
        </Background>
    );
};
export default Beredskapsomrade;
