import { SET_ACCOUNT, SET_ACCOUNT_FAIL } from "./types";

export const setAccount = (payload: any) => {
    return {
        type: SET_ACCOUNT,
        payload,
    };
};

export const setAccountFail = (payload: any) => {
    return {
        type: SET_ACCOUNT_FAIL,
        payload: payload,
    };
};
