import React, { useState, useContext } from "react";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
    RoleContext,
    RoleContextType,
} from "../common/contexts/RoleContextProvider";
import { roles } from "../../utils/enum";
import RoleViews from "./RoleViews";

export enum ROLLE {
    prest = 0,
    prostOgBeredskapsansvarlig = 1,
}

const useStyles = makeStyles((theme: Theme) => ({
    list: {
        marginTop: "2rem",
        paddingBottom: "0rem",
    },
    listItem: {
        paddingLeft: "2rem",
    },
    listIcon: {
        minWidth: "2.5rem",
    },
}));

const SettingsMenu: React.FC = () => {
    const [state, setState] = useState({
        visRoller: false,
        visOmrader: false,
    });
    const { roleList } = useContext(RoleContext) as RoleContextType;

    const visRoller = () => {
        setState({
            ...state,
            visRoller: !state.visRoller,
        });
    };

    const menuItems = [
        {
            icon: <SwapHorizIcon />,
            label: "Endre visning",
            isVisibleForUser:
                roleList.includes(roles.PREST) &&
                roleList.includes(roles.PROST),
            content: (
                <IconButton onClick={visRoller}>
                    {state.visRoller ? (
                        <ArrowUpIcon id="arrowUp" />
                    ) : (
                        <ArrowDownIcon id="arrowDown" />
                    )}
                </IconButton>
            ),
            expandedContent: <>{state.visRoller && <RoleViews />}</>,
            onClick: () => visRoller(),
        },
    ];
    const classes = useStyles();

    return (
        <List className={classes.list}>
            {menuItems
                .filter((i) => i.isVisibleForUser)
                .map((item, i) => (
                    <div key={i}>
                        <ListItem
                            className={classes.listItem}
                            onClick={item.onClick}
                            button
                        >
                            <ListItemIcon className={classes.listIcon}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.label} />
                            {item.content || null}
                        </ListItem>
                        {item.expandedContent || null}
                    </div>
                ))}
        </List>
    );
};

export default SettingsMenu;
