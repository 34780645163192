import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";

interface Props {
    feilmelding: string;
}

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        margin: "auto",
        paddingRight: "1.5rem",
        paddingLeft: "1.5rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
    },
    content: {
        paddingRight: "1.5rem",
        paddingLeft: "1.5rem",
        display: "flex",
    },
    icon: {
        textAlign: "center",
        marginRight: 8,
    },
}));
const DialogBoxAlert: React.FC<Props> = ({ feilmelding }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container className={classes.content}>
                <ErrorIcon className={classes.icon} color="error" />
                <Typography variant="body2">{feilmelding}</Typography>
            </Container>
        </div>
    );
};

export default DialogBoxAlert;
