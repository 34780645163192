import React, { useContext } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Hidden from "@material-ui/core/Hidden";
import { Prest } from "../../store/prester/types";
import PrestSettingsMenu from "./prestSettingsMenu";
import {
    RoleContext,
    RoleContextType,
} from "../common/contexts/RoleContextProvider";
import { roles } from "../../utils/enum";
import TelephoneNumber from "../common/TelephoneNumber";
import ToggleBeredskapDialogBox from "../beredskapsomrade/ToggleBeredskapDialogBox";

interface Props {
    prest: Prest;
}

const useStyles = makeStyles((theme: Theme) => ({
    chip: {
        background: "#43a047",
        color: theme.palette.common.black,
    },
    statusColumn: {
        width: 327,
    },
    errorMessage: {
        color: theme.palette.error.main,
    },
    moreOptions: {
        textAlign: "end",
    },
}));

const PresterTableRow: React.FC<Props> = ({ prest }) => {
    const { iBeredskap } = prest;
    const { role } = useContext(RoleContext) as RoleContextType;
    const classes = useStyles();

    return (
        <TableRow hover>
            <TableCell>{prest.navn}</TableCell>
            <Hidden smDown>
                <TableCell>
                    <TelephoneNumber telephonenumber={prest.telefonnummer} />
                </TableCell>
            </Hidden>
            <TableCell>
                <ToggleBeredskapDialogBox prest={prest} />
            </TableCell>
            <Hidden smDown>
                <TableCell className={classes.statusColumn}>
                    {iBeredskap && (
                        <Chip label="I BEREDSKAP" className={classes.chip} />
                    )}
                </TableCell>
            </Hidden>
            {role !== roles.PROST && (
                <TableCell className={classes.moreOptions}>
                    <PrestSettingsMenu prest={prest} />
                </TableCell>
            )}
        </TableRow>
    );
};

export default PresterTableRow;
