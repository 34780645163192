import {
    AUTHENTICATE_START,
    AUTHENTICATE_FAIL,
    AUTHENTICATE_SUCCESS,
} from "./types";

export const authenticateStart = () => {
    return {
        type: AUTHENTICATE_START,
    };
};

export const authenticateSuccess = () => {
    return {
        type: AUTHENTICATE_SUCCESS,
    };
};

export const authenticateFail = (e?: Error) => {
    return {
        type: AUTHENTICATE_FAIL,
        payload: e,
    };
};
