import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/Layout/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store/combinedReducers";
import { Kirketheme } from "./utils/theme";
import AuthProvider from "./authentication/AuthProvider";
import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SW_UPDATE, SW_INIT } from "./store/serviceWorker/types";
import { ToastContainer } from "react-toastify";
import { RoleContextProvider } from "./components/common/contexts/RoleContextProvider";
import { PrestContextProvider } from "./components/prester/contexts/PrestContextProvider";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthProvider>
                <ThemeProvider theme={Kirketheme}>
                    <ToastContainer />
                    <CssBaseline />
                    <RoleContextProvider>
                        <PrestContextProvider>
                            <App />
                        </PrestContextProvider>
                    </RoleContextProvider>
                </ThemeProvider>
            </AuthProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorker.register({
    onSuccess: () => store.dispatch({ type: SW_INIT }),
    onUpdate: (registration) =>
        store.dispatch({ type: SW_UPDATE, payload: registration }),
    });