import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Kontaktliste from "./KontaktListe";
import PrestIBeredskap from "../beredskapsomrade/prestIBeredskap";
import { Kontaktinfo } from "../../store/prester/types";
import { Beredskapsomrade } from "../types";

const useStyles = makeStyles((theme) => ({
    mainTitle: {
        marginBottom: "1rem",
    },
}));

interface Props {
    prester: Kontaktinfo[];
    beredskapsomrade: Beredskapsomrade;
}

const PresterIBeredskapsomradetMobil: React.FC<Props> = ({
    prester,
    beredskapsomrade,
}) => {
    const classes = useStyles();

    const getThreeFirst = (personer: Kontaktinfo[]) => {
        const sortedPersoner = [...personer].sort((a, b) =>
            a.navn.localeCompare(b.navn)
        );
        return sortedPersoner.filter((x) => x.iBeredskap).slice(0, 2);
    };

    return (
        <>
            <Typography variant="h4" className={classes.mainTitle}>
                {beredskapsomrade.navn}
            </Typography>

            {getThreeFirst(prester)?.map((prest, index) => (
                <div key={index}>
                    <PrestIBeredskap prest={prest} />
                </div>
            ))}

            <Kontaktliste kontaktpersoner={prester} tittel="Prest:" />
        </>
    );
};
export default PresterIBeredskapsomradetMobil;
