import React, { useState, createContext } from "react";
import { Beredskapsomrade } from "../../prestSokeResultater/types";

export type PrestContextType = {
    beredskapsomrader: Beredskapsomrade[];
    setBeredskapsomrader: (res: any) => void;
};

export const PrestContext = createContext<PrestContextType | {}>({});

interface Props {
    children: React.ReactNode;
}

export const PrestContextProvider: React.FC<Props> = ({ children }) => {
    const [beredskapsomrader, setBeredskapsomrader] = useState<
        Beredskapsomrade[]
    >([]);
    return (
        <PrestContext.Provider
            value={{
                beredskapsomrader,
                setBeredskapsomrader,
            }}
        >
            {children}
        </PrestContext.Provider>
    );
};
