import React from "react";
import SearchInput from "../common/SearchInput";
import { Role } from "./role";
import DialogBoxAlert from "../common/DialogBoxAlert";
import { User, UserSearchResponse } from "../types";

interface Props {
    role: Role;
    handleSearch: (searchText: string) => void;
    searchResultsUser: User[] | undefined;
    error: Error | undefined;
    loading: boolean;
    searchResults: UserSearchResponse | undefined;
}

const UserSearchInput: React.FC<Props> = ({
    role,
    handleSearch,
    searchResultsUser,
    error,
    loading,
    searchResults,
}) => {
    return (
        <>
            <SearchInput
                handleSearch={handleSearch}
                placeholderText={`Søk etter ${role} (fornavn etternavn)`}
            />

            {searchResultsUser?.length === 0 && !error && !loading && (
                <DialogBoxAlert
                    feilmelding={`Det finnes ingen ${role} med dette navnet. Søk på nytt.`}
                />
            )}

            {error && (
                <DialogBoxAlert
                    feilmelding={`Søket kan ikke være tomt. Skriv inn navnet på en ${role}.`}
                />
            )}

            {searchResults &&
                searchResults.count > searchResults.limit &&
                !error &&
                !loading && (
                    <p>
                        Resultatet viser de {searchResults.limit} første {role}
                        ene fra søket. Hvis du ikke finner {role}en du leter
                        etter må du presisere søket.
                    </p>
                )}
        </>
    );
};
export default UserSearchInput;
