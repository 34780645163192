import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { getTokenSilent } from "../../authentication/authModule";
import { HttpConfig, baseUrl, defaultHttpConfig } from "./HttpConfig";
import { CUSTOM_ERRORS, feilmeldinger } from "../../utils/constants";

axios.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
        return response;
    },
    (error) => {
        const clientError =
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500;

        if (error.response.status === 403) {
            console.log("Forbidden");
        }
        if (error.response.status === 401) {
            console.log("Unauthorized");
        }

        if (clientError) {
            console.log("Client error");
        }
    }
);

interface ProblemDetails {
    type: string;
    title: string;
    status: number;
    errors: { [key: string]: string[] };
    errorMessage: string;
}

const convertToProblemDetails = (
    problemDetailsData: ProblemDetails
): ProblemDetails => {
    const { errors } = problemDetailsData;

    var errormsg = Object.values(errors)[0][0];

    return {
        type: problemDetailsData.type,
        title: problemDetailsData.title,
        status: problemDetailsData.status,
        errors: errors,
        errorMessage: errormsg,
    };
};

const handleError = (error: AxiosError) => {
    if (error.response) {
        if (error.response.status === 400) {
            const problemDetails = convertToProblemDetails(error.response.data);
            throw new ValidationError(problemDetails.errorMessage);
        }
        else if (error.response.status === 404) {
            const problemDetails = convertToProblemDetails(error.response.data);
            throw new NotFoundError(problemDetails.errorMessage);
        }
        } else {
            throw new Error(feilmeldinger.uventetFeil);
        }
};

class ValidationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = CUSTOM_ERRORS.ValidationError;
    }
}

export class NotFoundError extends Error {
    constructor(message: string) {
        super(message);
        this.name = CUSTOM_ERRORS.NotFoundError;
    }
}


export const httpClient = (config: HttpConfig = defaultHttpConfig) =>
    axios.create(config);

export const httpCall = async (
    url: string,
    options: AxiosRequestConfig
): Promise<any> => {
    const token = await getTokenSilent();
    const config = new HttpConfig(token);

    try {
        const response = await httpClient(config).request({
            url: baseUrl + url,
            ...options,
        });
        return response.data;
    } catch (e) {
        return handleError(e);
    }
};

export const httpGet = async (url: string): Promise<any> => {
    return httpCall(url, { method: "get" });
};

export const httpPost = async (url: string, body: Object): Promise<any> => {
    return httpCall(url, { data: body, method: "post" });
};

export const httpPut = async (url: string, body: Object): Promise<any> => {
    return httpCall(url, { data: body, method: "put" });
};

export const httpDelete = async (url: string): Promise<any> => {
    return httpCall(url, { method: "delete" });
};
