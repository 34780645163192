import { createMuiTheme } from "@material-ui/core";

export const Kirketheme = createMuiTheme({
    palette: {
        primary: {
            main: "#393938",
        },
        secondary: {
            main: "#F2F2F2",
        },
        common: {
            black: "#000",
            white: "#fff",
        },
        error: {
            main: "#D90011",
        },
        warning: {
            main: "#FF9100",
        },
        info: {
            main: "#0578CA",
        },
        success: {
            main: "#06893A",
        },
        type: "light",
    },

    typography: {
        fontFamily: "Arial",
        fontSize: 14,
    },
});
