import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Prest } from "../../store/prester/types";
import TelephoneNumber from "../common/TelephoneNumber";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
    background: {
        padding: "2rem",
        background: "#F2F2F2",
        borderRadius: "4px",
        marginBottom: "1rem",
    },
    wrapper: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        [theme.breakpoints.up("lg")]: {
            width: "60%",
        },
    },
    body: {
        marginBottom: "0.5rem",
        [theme.breakpoints.down("sm")]: {
            marginTop: "1rem",
        },
    },
}));

interface Props {
    prest: Prest;
}

const PrestIBeredskap: React.FC<Props> = ({ prest }) => {
    const classes = useStyles();

    return (
        <div className={classes.background}>
            <div className={classes.wrapper}>
                <div>
                    <Typography className={classes.body}>
                        Prest i beredskap:
                    </Typography>
                    <Typography variant="h5" className={classes.body}>
                        {prest?.navn}
                    </Typography>
                </div>
                <div>
                    <Typography className={classes.body}>
                        Telefonnummer til prest:
                    </Typography>
                    <Typography variant="h5">
                        <TelephoneNumber
                            telephonenumber={prest?.telefonnummer}
                        />
                    </Typography>
                </div>
            </div>
        </div>
    );
};
export default PrestIBeredskap;
