import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        marginRight: "1rem",
    },
}));

interface Props {
    handleClose: () => void;
    updateServiceWorker: () => void;
}
const Alertcontent: React.FC<Props> = ({
    handleClose,
    updateServiceWorker,
}) => {
    const classes = useStyles();

    return (
        <div>
            <Button
                size="small"
                className={classes.button}
                style={{ color: "#A4C6FF" }}
                onClick={updateServiceWorker}
            >
                Oppdater
            </Button>
            <IconButton
                data-testid="closeButton"
                size="small"
                color="inherit"
                aria-label="lukk"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </div>
    );
};

export default Alertcontent;
