import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
    notificationIcon: {
        marginRight: "0.1rem",
        marginLeft: "2rem", 
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
        },
    },
    iconText: {
        marginLeft: "0.25rem",
        marginRight: "auto",
        display: "flex",
        alignItems: "flex-start",
    },
}));

const NotificationIcon: React.FC = () => {
    const classes = useStyles();

    return (
        <>
                <NotificationsActiveIcon
                    className={classes.notificationIcon}
                    color="disabled"
                    aria-label="Varslig er slått på."
                />
         <Hidden smDown>
                <Typography variant="subtitle1" className={classes.iconText}>
                    Varsel på
                </Typography>
          </Hidden>
        </>
    );
};
export default NotificationIcon;
