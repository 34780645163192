import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        borderColor:
            theme.palette.type === "light"
                ? theme.palette.common.black
                : theme.palette.common.white,
        margin: "auto",
    },
    title: {
        marginBottom: "1.3rem",
    },
}));

interface Props {
    handleSearch: (searchText: string) => void;
    placeholderText: string;
    searchInputTitle?: string;
}

const SearchInput: React.FC<Props> = ({
    handleSearch,
    placeholderText,
    searchInputTitle,
}) => {
    const classes = useStyles();

    const [searchText, setSearchText] = useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
        event.preventDefault();
        handleSearch(searchText);
    };

    return (
        <>
            {searchInputTitle && (
                <Typography variant="h6" className={classes.title}>
                    {searchInputTitle}
                </Typography>
            )}
            <Paper
                elevation={0}
                variant="outlined"
                component="form"
                className={classes.root}
                onSubmit={handleSubmit}
            >
                <InputBase
                    className={classes.input}
                    placeholder={placeholderText}
                    onChange={handleChange}
                    value={searchText}
                    autoFocus
                />
                <IconButton
                    id="searchButton"
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                >
                    <SearchIcon />
                </IconButton>
            </Paper>
        </>
    );
};
export default SearchInput;
