import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import { User } from "../types";

const useStyles = makeStyles((theme: Theme) => ({
    navn: {
        margin: "0",
    },
    listItem: {
        justifyContent: "space-between",
        alignItems: "center",
        background: theme.palette.secondary.main,
        marginBottom: "0.5rem",
        "&:hover": {
            background: theme.palette.secondary.dark,
        },
        borderRadius: "10px",
    },
}));

interface Props {
    selectedId: string;
    searchResultsUser: User[] | undefined;
    handleItemClick: (id: number, item: any) => void;
    handleChangeRadio: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserSearchList: React.FC<Props> = ({
    selectedId,
    searchResultsUser,
    handleItemClick,
    handleChangeRadio,
}) => {
    const classes = useStyles();
    return (
        <List>
            {searchResultsUser?.map((user, i) => (
                <div key={i}>
                    <ListItem
                        alignItems="flex-start"
                        button
                        selected={selectedId === `${i}`}
                        className={classes.listItem}
                        onClick={() => handleItemClick(i, user)}
                    >
                        <div>
                            <Typography className={classes.navn}>
                                {user.name}
                            </Typography>
                            <small>{user.email}</small>
                        </div>
                        <Radio
                            color="primary"
                            checked={selectedId === `${i}`}
                            onChange={handleChangeRadio}
                            value={i}
                            name="radio-button"
                        />
                    </ListItem>
                </div>
            ))}
        </List>
    );
};
export default UserSearchList;
