import React from "react";
import ListMUI from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import NotificationIcon from "../common/NotificationIcon";
import { PresterIBeredskap, Beredskapsomrade } from "../types";
import { Container } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import OmradeStatus from "./OmradeStatus";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "inline-flex",
    },
    nameWrapper: {
        display: "flex",
        flexDirection: "column",
        flexFlow: "wrap",
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "90%",
        },
    },
    nextIcon: {
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        marginLeft: "0.5rem",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0",
        },
    },
    header: {
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        marginLeft: "0.5rem",
    },
    errorIcon: {
        fill: "#D90011",
        marginRight: "0.2rem",
        marginBottom: "0.1rem",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0",
            marginRight: "1rem"
        },
    },
    errorContent: {
        fill: "#D90011",
        background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #D90011;",
        display: "flex",
        borderRadius: "0.25rem",
        maxWidth: 280,
        padding: "0.5rem",
        marginLeft: 0,
    },
    warningIcon: {
        fill: "#F4D150",
        marginRight: "0.2rem",
        marginBottom: "0.1rem",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0",
            marginRight: "1rem"
        },
    },
    warningContent: {
        fill: "#F4D150",
        background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F4D150;",
        display: "flex",
        borderRadius: "0.25rem",
        maxWidth: 280,
        padding: "0.5rem",
        marginLeft: 0,
    },
    successIcon: {
        marginRight: "0.2rem",
        fill: "#06893A",
        marginBottom: "0.1rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "large",
            marginBottom: "auto",
            marginRight: "0.5rem",
        },
    },
    successContent: {
        marginLeft: 0,
        fill: "#F4D150",
        display: "flex",
        borderRadius: "0.25rem",
        maxWidth: 280,
        padding: "0.5rem",
    },
    prestIBerdskapMobil: {
        marginLeft: "0.5rem",
    },
    iconText: {
        marginLeft: "0.2rem",
        marginRight: "0.2rem",
    },
    placeholder: {
        marginLeft: "2rem",
        marginRight: "auto",
        width: "8%",
    },
}));

interface Props {
    beredskapsomrader: Beredskapsomrade[];
    beredskapsInfo: PresterIBeredskap[];
    handleClickListItem: (listItem: Beredskapsomrade) => void;
}

const BeredskapsomradeListe: React.FC<Props> = ({
    beredskapsomrader,
    beredskapsInfo,
    handleClickListItem,
}) => {
    const classes = useStyles();

    const theme = useTheme();
    const isOnMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

const nameOfPrestIBeredskap = (id: number) => {
    if (beredskapsInfo) {
        const currentBeredskapInfo = beredskapsInfo.find(
            (info) => info.beredskapsomradeId === id
        );
        if (currentBeredskapInfo && currentBeredskapInfo.presterIBeredskap.length > 0) {
            return currentBeredskapInfo.presterIBeredskap[0].name;
        }
    }
    return "";
};

    const antallPresterIBeredskap = (id: number) => {
        if (beredskapsInfo) {
            const currentBeredskapInfo = beredskapsInfo.find(
                (info) => info.beredskapsomradeId === id
            ); 
            if (currentBeredskapInfo){
                return currentBeredskapInfo.antallPresterIBeredskap;
            }
        } 
        return -1;
    };

    return (
        <ListMUI>
            {beredskapsomrader.map((area) => (
                <ListItem
                    disableGutters
                    divider
                    button
                    key={area.id}
                    onClick={() => handleClickListItem(area)}
                    className={classes.container}
                >
                    <NavigateNextIcon className={classes.nextIcon} />
                    <div className={classes.nameWrapper}>
                        <Typography className={classes.header} variant={"h6"}>
                            {area.navn}
                        </Typography>
                        {isOnMobileDevice &&
                            antallPresterIBeredskap(area.id) === 1 && (
                                <Typography
                                    variant="subtitle1"
                                    className={classes.prestIBerdskapMobil}
                                >
                                    <CheckCircleIcon
                                        className={classes.successIcon}
                                    />
                                    {nameOfPrestIBeredskap(area.id)}
                                </Typography>
                            )}
                    </div>

                    {antallPresterIBeredskap(area.id) > 1 && (
                        <OmradeStatus
                            text="For mange prester i beredskap"
                            ariaLabel="Beredskapsområdet har for mange prester i beredskap."
                            iconStyle={classes.warningIcon}
                            containerStyle={classes.warningContent}
                        />
                    )}

                    {antallPresterIBeredskap(area.id) === 0 && (
                        <OmradeStatus
                            text="Mangler prest i beredskap"
                            ariaLabel="Beredskapsområdet mangler prest i beredskap."
                            iconStyle={classes.errorIcon}
                            containerStyle={classes.errorContent}
                        />
                    )}

                    {!isOnMobileDevice &&
                        antallPresterIBeredskap(area.id) === 1 && (
                            <>
                                <Container className={classes.successContent}>
                                    <CheckCircleIcon
                                        className={classes.successIcon}
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.iconText}
                                    >
                                        {nameOfPrestIBeredskap(area.id)}
                                    </Typography>
                                </Container>
                            </>
                        )}

                    {area.varsel ? (
                        <NotificationIcon />
                    ) : (
                        <div className={classes.placeholder} />
                    )}
                </ListItem>
            ))}
        </ListMUI>
    );
};
export default BeredskapsomradeListe;
