import { TokenType } from "../utils/types";
import { roles } from "./enum";

export const getRoleByPriority = (token: TokenType) => {
    if (token.roles.includes(roles.ADMIN)) {
        return roles.ADMIN;
    }
    if (token.roles.includes(roles.BEREDSKAPSANSVARLIG)) {
        return roles.BEREDSKAPSANSVARLIG;
    }
    if (token.roles.includes(roles.PROST)) {
        return roles.PROST;
    }
    if (token.roles.includes(roles.PREST)) {
        return roles.PREST;
    }
    if (token.roles.includes(roles.SECURITAS)) {
        return roles.SECURITAS;
    }
    return "Unknown";
};
