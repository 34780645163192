import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Prest } from "../../store/prester/types";
import RemovePrestDialogBox from "./RemovePrestDialogBox";
import SettingsMenu from "../common/SettingsMenu";

interface Props {
    prest: Prest;
}

interface ParamTypes {
    beredskapsomrade_id: string;
}

const PrestSettingsMenu: React.FC<Props> = ({ prest }) => {
    const { beredskapsomrade_id } = useParams<ParamTypes>();
    const [open, setOpen] = useState(false);

    return (
        <>
            <SettingsMenu
                menuItems={[
                    {
                        menuItemText: "Fjern prest fra området",
                        setOpen: setOpen,
                    },
                ]}
            />

            <RemovePrestDialogBox
                omradeId={beredskapsomrade_id}
                open={open}
                setOpen={setOpen}
                prest={prest}
            />
        </>
    );
};

export default PrestSettingsMenu;
