import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: "1rem",
    },
    link: {
        "&:hover": {
            cursor: "pointer",
        },
    },
}));

interface Props {
    root: { name: string; url: string };
    paths: { name: string; url: string }[];
}

const Breadcrumb: React.FC<Props> = ({ root, paths }) => {
    const classes = useStyles();

    const history = useHistory();

    const handleClick = (pathName: string) => {
        history.push(pathName);
    };

    const handleKeyPress = (e: React.KeyboardEvent, path: string) => {
        if (e.key === "Enter") {
            handleClick(path);
        }
    };
    return (
        <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
            <Link
                color="inherit"
                tabIndex={0}
                onKeyPress={(e: React.KeyboardEvent) =>
                    handleKeyPress(e, root.url)
                }
                className={classes.link}
                onClick={() => handleClick(root.url)}
            >
                {root.name}
            </Link>
            {paths.map((path, i) => {
                const isLastPath = i === paths.length - 1;

                return isLastPath ? (
                    <Typography color="textPrimary" key={i}>
                        {path.name}
                    </Typography>
                ) : (
                    <Link
                        className={classes.link}
                        key={i}
                        tabIndex={0}
                        color="inherit"
                        onClick={() => handleClick(path.url)}
                        onKeyPress={(e: React.KeyboardEvent) =>
                            handleKeyPress(e, path.url)
                        }
                    >
                        {path.name}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default Breadcrumb;
