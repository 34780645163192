import React, { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IconButton, Menu, MenuItem } from "@material-ui/core";

interface Props {
    menuItems: MenuItem[];
}

export type MenuItem = {
    menuItemText: string;
    setOpen: (open: boolean) => void;
};

const SettingsMenu: React.FC<Props> = ({ menuItems }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="settings"
                aria-controls="settings-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems.map((item, i) => (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            item.setOpen(true);
                        }}
                        key={i}
                    >
                        {item.menuItemText}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default SettingsMenu;
