import React from "react";
import TableBodyMUI from "@material-ui/core/TableBody";

interface Props {
    data: Array<any>;
    children: (item: any, key: number) => React.ReactNode;
}

const TableBodyWrapper: React.FC<Props> = ({ data, children }) => {
    return (
        <TableBodyMUI>
            {data.map((item: any, key: number) => children(item, key))}
        </TableBodyMUI>
    );
};

export default TableBodyWrapper;
