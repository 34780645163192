import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useLoader } from "../customHooks/useLoader";
import { httpGet } from "../../services/http/httpService";
import LinearProgress from "@material-ui/core/LinearProgress";
import InfoBox from "../common/InfoBox";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { User } from "../types";
import { Role } from "../leggTilBruker/role";
import ProsterListe from "./ProsterListe";
import { customToast } from "../../services/varslinger/varslingsService";
import { feilmeldinger } from "../../utils/constants";
import AddUserDialogBox from "../leggTilBruker/AddUserDialogBox";

const useStyles = makeStyles(() => ({
    header: {
        marginTop: "3rem",
    },
    message: {
        marginBottom: "1rem",
        marginTop: "1rem",
        fontStyle: "italic",
    },
}));

interface Props {
    bispedomme_id: string;
}

const Proster: React.FC<Props> = ({ bispedomme_id }) => {
    const [proster, setProster] = useState<User[]>([]);

    const { loading, error } = useLoader(() =>
        httpGet(`api/Bispedommer/${bispedomme_id}/proster`).then(
            (res) => res && setProster(res)
        )
    );

    const leggTilProst = (prost: User) => {
        setProster((previousState) => [...previousState, prost]);
        customToast.success("Prost ble lagt til i bispedømmet.");
    };

    const removeProst = (prostId: number) => {
        setProster(proster.filter((p: User) => p.id !== prostId));
        customToast.success("Prost ble fjernet fra området.");
    };

    const classes = useStyles();

    return (
        <>
            <Typography className={classes.header} variant="h5">
                Proster i bispedømmet
            </Typography>
            {loading && <LinearProgress />}
            {error ? (
                <InfoBox
                    message={feilmeldinger.uventetFeil}
                    icon={<ErrorOutlineIcon />}
                />
            ) : (
                <>
                    <ProsterListe proster={proster} removeProst={removeProst} />
                    {proster?.length === 0 && (
                        <p className={classes.message}>
                            {feilmeldinger.ingenProsterLagtTilIBispedommet}
                        </p>
                    )}
                </>
            )}
            <AddUserDialogBox
                omradeId={bispedomme_id}
                leggTil={leggTilProst}
                role={Role.PROST}
            />
        </>
    );
};
export default Proster;
