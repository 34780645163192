import React from "react";
import ListMUI from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ProstSettingsMenu from "./ProstSettingsMenu";
import { User } from "../types";

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        marginLeft: "0.5rem",
        width: "98%",
    },
}));

interface Props {
    proster: User[];
    removeProst: (id: number) => void;
}

const ProsterListe: React.FC<Props> = ({ proster, removeProst }) => {
    const classes = useStyles();
    const sortedProster = [...proster].sort((a, b) =>
        a.name.localeCompare(b.name)
    );
    return (
        <ListMUI>
            {sortedProster?.map((prost, i) => (
                <ListItem disableGutters divider key={i}>
                    <Typography className={classes.header} variant={"h6"}>
                        {prost.name}
                    </Typography>
                    <ProstSettingsMenu
                        prost={prost}
                        removeProst={removeProst}
                    />
                </ListItem>
            ))}
        </ListMUI>
    );
};
export default ProsterListe;
