import React from "react";
import UserInfo from "../UserInfo";
import Divider from "@material-ui/core/Divider";
import SettingsMenu from "../SettingsMenu";
import Logout from "../Logout";
import KirkeLogo from "../KirkeLogo";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    content: {
        flexGrow: 1,
    },
    list: {
        width: "18rem",
    },
}));

const MobileView: React.FC = () => {
    const classes = useStyles();

    return (
        <div role="presentation" className={classes.root}>
            <div className={classes.content}>
                <UserInfo />
                <br></br>
                <Divider />

                <SettingsMenu />
            </div>
            <Logout />
            <Divider />
            <KirkeLogo />
        </div>
    );
};

export default MobileView;
