import React, { useContext, useState } from "react";
import Tabell from "../common/Table";
import { makeStyles } from "@material-ui/core/styles";
import { Prest } from "../../store/prester/types";
import { feilmeldinger } from "../../utils/constants";
import {
    RoleContext,
    RoleContextType,
} from "../common/contexts/RoleContextProvider";
import { roles } from "../../utils/enum";
import PresterTableRow from "../prester/PresterTableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const useStyles = makeStyles(() => ({
    icon: {
        float: "right",
    },
    message: {
        marginBottom: "1rem",
        marginTop: "1rem",
        fontStyle: "italic",
    },
}));

interface Props {
    prester: Prest[];
}

const BeredskapsomradeTabell: React.FC<Props> = ({ prester }) => {
    const classes = useStyles();
    const { role } = useContext(RoleContext) as RoleContextType;

    const [sortAsc, setSortAsc] = useState(true);
    const handleSort = () => {
        setSortAsc(!sortAsc);
    };

    const sortedPrester = sortAsc
        ? [...prester].sort((a, b) => a.navn.localeCompare(b.navn))
        : [...prester].sort((a, b) => a.navn.localeCompare(b.navn)).reverse();

    const columns = [
        {
            path: "navn",
            label: "Prest",
            content: () => (
                <TableSortLabel
                    active={true}
                    direction={sortAsc ? "asc" : "desc"}
                    onClick={handleSort}
                />
            ),
        },
        {
            path: "telefonnummer",
            label: "Telefonnummer",
            hideOnMobile: true,
        },
        {
            path: "",
            label: "Beredskap",
        },
        {
            path: "status",
            label: "Status",
            hideOnMobile: true,
        },
    ];

    if (role === roles.ADMIN || role === roles.BEREDSKAPSANSVARLIG) {
        columns.push({
            path: "",
            label: "",
        });
    }

    return (
        <>
            {prester.length > 0 ? (
                <Tabell columns={columns} data={sortedPrester}>
                    {(item: any, key: number) => (
                        <PresterTableRow prest={item} key={key} />
                    )}
                </Tabell>
            ) : (
                <p className={classes.message}>
                    {feilmeldinger.ingenPresterLagtTilIOmradet}
                </p>
            )}
        </>
    );
};

export default BeredskapsomradeTabell;
