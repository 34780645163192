import React from "react";
import CardMUI from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface Props {
    children: React.ReactNode;
    style?: any;
    padding?: string;
    background?: string;
}

const Card: React.FC<Props> = ({
    children,
    style,
    padding = "2.5rem",
    background = "white",
}) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            background: theme.palette.background.paper,
            padding,
            [theme.breakpoints.down("sm")]: {
                padding: "0.5rem",
            },
            ...style,
        },

        content: {
            [theme.breakpoints.down(330)]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    }));

    const classes = useStyles();

    return (
        <CardMUI className={classes.root}>
            <CardContent className={classes.content}>{children}</CardContent>
        </CardMUI>
    );
};

export default Card;
