import React, { useState } from "react";
import { useParams } from "react-router-dom";
import RemoveProstDialogBox from "./RemoveProstDialogBox";
import { User } from "../types";
import SettingsMenu from "../common/SettingsMenu";
import ProstVarslingerDialogBox from "./ProstVarslingerDialogBox";

interface Props {
    prost: User;
    removeProst: (id: number) => void;
}

interface ParamTypes {
    bispedomme_id: string;
}

const ProstSettingsMenu: React.FC<Props> = ({ prost, removeProst }) => {
    const { bispedomme_id } = useParams<ParamTypes>();
    const [openRemoveProst, setOpenRemoveProst] = useState(false);
    const [openVarslinger, setOpenVarslinger] = useState(false);

    const menuItems = [
        {
            menuItemText: "Fjern prost fra bispedømmet",
            setOpen: setOpenRemoveProst,
        },
        { menuItemText: "Administer varslinger", setOpen: setOpenVarslinger },
    ];
    return (
        <>
            <SettingsMenu menuItems={menuItems} />

            <RemoveProstDialogBox
                omradeId={bispedomme_id}
                open={openRemoveProst}
                setOpen={setOpenRemoveProst}
                prost={prost}
                removeProst={removeProst}
            />
            <ProstVarslingerDialogBox
                bispedommeId={bispedomme_id}
                open={openVarslinger}
                setOpen={setOpenVarslinger}
                prost={prost}
            />
        </>
    );
};

export default ProstSettingsMenu;
