import React from "react";
import UserInfo from "../UserInfo";
import SettingsMenu from "../SettingsMenu";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Logout from "../Logout";

const useStyles = makeStyles(() => ({
    root: {
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    settingsMenu: {
        alignSelf: "center",
        paddingRight: "2rem",
    },
}));

const DesktopView: React.FC = () => {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            <UserInfo />
            <div className={classes.settingsMenu}>
                <SettingsMenu />
                <Logout />
            </div>
        </Container>
    );
};

export default DesktopView;
