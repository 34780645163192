import {
    PrestState,
    PrestActionTypes,
    LEGG_TIL_PREST_FAIL,
    LEGG_TIL_PREST_START,
    LEGG_TIL_PREST_SUCCESS,
} from "./types";

export const initialState: PrestState = {
    loading: false,
    error: null,
};

export const presterReducer = (
    state = initialState,
    action: PrestActionTypes
) => {
    switch (action.type) {
        case LEGG_TIL_PREST_START:
            return {
                ...state,
                loading: true,
            };
        case LEGG_TIL_PREST_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case LEGG_TIL_PREST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
};
