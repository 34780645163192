import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    numberlink: {
        color: "inherit",
        textDecoration: "none",
    },
}));

interface Props {
    telephonenumber: string;
}

const TelephoneNumber: React.FC<Props> = ({ telephonenumber }) => {
    const classes = useStyles();

    const formatTelephoneNumber = (telephoneNumber: string) => {
        if (telephoneNumber) {
            return ` ${telephoneNumber.replace(/(\d{2})/g, "$1 ")}`;
        }
        return "-";
    };

    return (
        <a href={`tel: ${telephonenumber}`} className={classes.numberlink}>
            {formatTelephoneNumber(telephonenumber)}
        </a>
    );
};
export default TelephoneNumber;
