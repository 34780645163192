import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/combinedReducers";
import { login, handleRedirectPromise } from "./authModule";
import {
    authenticateStart,
    authenticateSuccess,
    authenticateFail,
} from "../store/auth/authActions";
import { setAccount } from "../store/account/accountActions";
import Unauthorized from "../components/Pages/Unauthorized";
import LinearProgress from "@material-ui/core/LinearProgress";

interface Props {
    children: ReactNode;
}

const AuthProvider: React.FC<Props> = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        handleRedirectPromise()
            .then((response) => {
                dispatch(authenticateStart());
                if (response !== null) {
                    dispatch(authenticateSuccess());
                    dispatch(setAccount(response.account));
                } else {
                    login();
                }
            })
            .catch((e) => {
                dispatch(authenticateFail(e));
            });
    }, [dispatch]);

    const authenticated = useSelector(
        (state: RootState) => state.authReducer.authenticated
    );

    const checkAuth = () => {
        if (authenticated === null) {
            return <LinearProgress />;
        }
        if (authenticated) {
            return <>{children}</>;
        }
        if (!authenticated) {
            return <Unauthorized />;
        }
        return <div></div>;
    };

    return <>{checkAuth()}</>;
};

export default AuthProvider;
