import { AccountInfo } from "@azure/msal-browser";

export type AccountAction = {
    type: string;
    payload: any;
};

export interface AccountState {
    account: AccountInfo | undefined;
    error: any;
}

export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_ACCOUNT_FAIL = "SET_ACCOUNT_FAIL";

export type AccountActionTypes = AccountAction;
