import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { httpGet } from "../../services/http/httpService";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import UserSearchInput from "./UserSearchInput";
import UserSearchList from "./UserSearchList";
import { Role } from "./role";
import { User, UserSearchResponse } from "../types";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        borderColor: theme.palette.common.black,
        margin: "auto",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    selected: {
        textDecoration: "underline",
    },

    limitText: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
}));

interface Props {
    role: Role;
    selectedId: string;
    selected: User;
    handleChangeRadio: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleItemClick: (id: number, item: any) => void;
    clearSelected: () => void;
}

const AddUserDialogBoxContent: React.FC<Props> = ({
    role,
    selectedId,
    selected,
    handleChangeRadio,
    handleItemClick,
    clearSelected,
}) => {
    const classes = useStyles();

    const [searchResults, setSearchResults] = useState<UserSearchResponse>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const responseLimit = 5;

    const handleSearch = async (searchText: string) => {
        setSearchResults(undefined);
        setError(undefined);
        clearSelected();
        setLoading(true);
        try {
            const result = await httpGet(
                `api/Users/search?SearchText=${searchText}&Limit=${responseLimit}&Role=${
                    role[0].toUpperCase() + role.substring(1)
                }`
            );
            setSearchResults(result);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <UserSearchInput
                role={role}
                handleSearch={handleSearch}
                searchResultsUser={searchResults?.users}
                error={error}
                loading={loading}
                searchResults={searchResults}
            />

            {loading ? (
                <LinearProgress />
            ) : (
                <UserSearchList
                    selectedId={selectedId}
                    searchResultsUser={searchResults?.users}
                    handleItemClick={handleItemClick}
                    handleChangeRadio={handleChangeRadio}
                />
            )}

            {selected.name && (
                <Typography variant="subtitle1" id="selected">
                    <span className={classes.selected}>{selected.name}</span>
                    <span>{" legges til i listen."}</span>
                </Typography>
            )}
        </div>
    );
};
export default AddUserDialogBoxContent;
