import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import BeredskapsomradeListe from "./BeredskapsomradeListe";
import { Beredskapsomrade, PresterIBeredskap } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Hidden } from "@material-ui/core";


interface Props {
    beredskapsomrader: Beredskapsomrade[];
    presterIBeredskap: PresterIBeredskap[];
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        allignItems: "flex-start",
    },
    beredskapsOmraderText: {
        marginLeft: "0.2rem",
        marginRight: "auto",
        width: "21%",
    },
    placeholder: {
        marginLeft: "0.6rem",
        marginRight: "auto",
    },
    statusOversiktText: {
        marginRight: "auto",
        marginLeft: "1rem",
    },
}));

const Beredskapsomrader: React.FC<Props> = ({
    beredskapsomrader,
    presterIBeredskap,
}) => {
    const history = useHistory();
    const currentPath = useLocation().pathname;

    const handleClickListItem = (beredskapsomrade: Beredskapsomrade) => {
        history.push(`${currentPath}/beredskapsomrader/${beredskapsomrade.id}`);
    };
    const classes = useStyles();
    return (
        <>
             <div className={classes.container}>
                <Typography
                    variant="h5"
                    className={classes.beredskapsOmraderText}
                >
                    Beredskapsområder
                </Typography>

                <Hidden smDown>
                    <Typography
                        variant="h5"
                        className={classes.statusOversiktText}
                    >
                        Prest i beredskap
                    </Typography>
                </Hidden>
            </div>
            <BeredskapsomradeListe
                beredskapsomrader={beredskapsomrader}
                beredskapsInfo={presterIBeredskap}
                handleClickListItem={handleClickListItem}
            />
        </>
    );
};
export default Beredskapsomrader;
