import { useState, useEffect } from "react";
import { httpPut } from "../../services/http/httpService";
import { customToast } from "../../services/varslinger/varslingsService";
import { feilmeldinger, toastVarslinger } from "../../utils/constants";
import { roles } from "../../utils/enum";
import { RoleType } from "../../utils/types";
import { UserType } from "../common/contexts/UserContextProvider";

export interface Notification<T> {
    notification: boolean;
    loadingNotification: boolean;
    handleToggleNotification: () => void;
}

export function useNotification<T>(
    omradeId: string,
    user: UserType,
    role: RoleType,
    initialValue: boolean | undefined
): Notification<T> {
    const [notification, setNotification] = useState(false);
    const [loadingNotification, setLoadingNotification] = useState(false);

    useEffect(() => {
        if (typeof initialValue === "boolean") {
            setNotification(initialValue);
        }
    }, [initialValue]);

    const { putUrl, putBody } = putVarselParameters(
        role,
        omradeId,
        user,
        notification
    );

    const handleToggleNotification = async () => {
        setLoadingNotification(true);
        try {
            const response = await httpPut(putUrl, putBody);
            setNotification(response.varsel);
            customToast.success(toastText(role, response.varsel));
        } catch (e) {
            customToast.error(feilmeldinger.uventetFeil);
        } finally {
            setLoadingNotification(false);
        }
    };

    return {
        notification,
        loadingNotification,
        handleToggleNotification,
    };
}

const putVarselParameters = (
    role: RoleType,
    omradeId: string,
    user: UserType,
    notification: boolean
) => {
    let putUrl = "";
    let putBody = {};
    if (role === roles.BEREDSKAPSANSVARLIG || role === roles.ADMIN) {
        putUrl = `api/Bispedommer/${omradeId}/varsel`;
        putBody = {
            userId: user.id,
            bispedommeId: omradeId,
            varsel: !notification,
        };
    } else if (role === roles.PROST) {
        putUrl = `api/Beredskapsomrader/${omradeId}/varsel`;
        putBody = {
            userId: user.id,
            beredskapsomradeId: omradeId,
            varsel: !notification,
        };
    }
    return { putUrl, putBody };
};

const toastText = (role: RoleType, notification: any) => {
    if (role === roles.BEREDSKAPSANSVARLIG || role === roles.ADMIN) {
        return notification
            ? toastVarslinger.varselPaaIBispedommet
            : toastVarslinger.varselAvIBispedommet;
    } else if (role === roles.PROST) {
        return notification
            ? toastVarslinger.varselPaaIBeredskapsomradet
            : toastVarslinger.varselAvIBeredskapsomradet;
    }
    return toastVarslinger.uventetFeil;
};
