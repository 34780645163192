export const LEGG_TIL_PREST_START = "LEGG_TIL_PREST_START";
export const LEGG_TIL_PREST_SUCCESS = "LEGG_TIL_PREST_SUCCESS";
export const LEGG_TIL_PREST_FAIL = "LEGG_TIL_PREST_FAIL";

export interface PrestState {
    loading: boolean;
    error: any;
}

interface PrestAction {
    type: string;
    payload?: any;
}

export interface Prest {
    id: number;
    azureAdId: string;
    navn: string;
    telefonnummer: string;
    iBeredskap: boolean;
}

export interface Kontaktinfo {
    id: number;
    azureAdId: string;
    navn: string;
    telefonnummer: string;
    iBeredskap: boolean;
}

export type PrestActionTypes = PrestAction;
