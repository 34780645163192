import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Typography from "@material-ui/core/Typography";
import {
    RoleContextType,
    RoleContext,
} from "../common/contexts/RoleContextProvider";
import { roles } from "../../utils/enum";

const useStyles = makeStyles((theme) => ({
    varsling: {
        display: "flex"
    },
    notificationIcon: {
        marginRight: "0.5rem",
    },
}));

interface Props {
    notification: boolean;
}

const NotificationStatus: React.FC<Props> = ({ notification }) => {
    const classes = useStyles();

    const { role: currentUserRole } = useContext(
        RoleContext
    ) as RoleContextType;

    let text = "";
    if (
        currentUserRole === roles.BEREDSKAPSANSVARLIG ||
        currentUserRole === roles.ADMIN
    ) {
        text = "Du får varsel for dette bispedømmet.";
    } else if (currentUserRole === roles.PROST) {
        text = "Du får varsel for dette beredskapsområdet.";
    }

    return (
        <>
            {notification ? (
                <div className={classes.varsling}>
                    <NotificationsActiveIcon
                        className={classes.notificationIcon}
                        color="disabled"
                        aria-label="varsel ikon"
                    />
                    <Typography>{text}</Typography>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
export default NotificationStatus;
