import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { visKommuner } from "../common/VisKommuner";
import { Beredskapsomrade, Kommune } from "../prestSokeResultater/types";

const useStyles = makeStyles((theme) => ({
    mainTitle: {
        marginBottom: "1rem",
    },
    kommuner: {
        marginBottom: "3rem",
    },
}));

interface Props {
    beredskapsomrade: Beredskapsomrade;
    kommuner: Kommune[];
}

const BeredskapsomradeMedKommunerTittel: React.FC<Props> = ({
    beredskapsomrade,
    kommuner,
}) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h4" className={classes.mainTitle}>
                {beredskapsomrade.navn}
            </Typography>
            <div className={classes.kommuner}>
                {visKommuner(kommuner, kommuner.length)}
            </div>
        </>
    );
};
export default BeredskapsomradeMedKommunerTittel;
