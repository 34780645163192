import React from "react";
import TableMUI from "@material-ui/core/Table";
import TableHeader from "./TableHeader";
import { Column } from "./types";
import TableBodyWrapper from "../common/TableBodyWrapper";

interface Props {
    columns: Column[];
    data: Array<any>;
    children: (item: any, key: number) => React.ReactNode;
}

const Table: React.FC<Props> = ({ columns, data, children }) => {
    return (
        <TableMUI>
            <TableHeader columns={columns} />
            <TableBodyWrapper data={data}>
                {(item: any, key: number) => children(item, key)}
            </TableBodyWrapper>
        </TableMUI>
    );
};

export default Table;
