import React, { useState, useContext } from "react";
import DialogBox from "../common/DialogBox";
import { httpPut } from "../../services/http/httpService";
import {
    BeredskapsomradeContext,
    BeredskapsContextType,
} from "../beredskapsomrade/contexts/beredskapsomradeContext";
import { useParams } from "react-router-dom";
import { Prest } from "../../store/prester/types";
import { customToast } from "../../services/varslinger/varslingsService";
import { feilmeldinger } from "../../utils/constants";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    chip: {
        background: "#F4D150",
        color: theme.palette.common.black,
    },
    dialogContent: {
        marginTop: "16px",
        marginBottom: "16px",
    },
}));

interface Props {
    prest: Prest;
}

interface ParamTypes {
    beredskapsomrade_id: string;
}

const ToggleBeredskapDialogBox: React.FC<Props> = ({ prest }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const { iBeredskap } = prest;
    const { updateIBeredskap } = useContext(
        BeredskapsomradeContext
    ) as BeredskapsContextType;
    const classes = useStyles();

    const { beredskapsomrade_id } = useParams<ParamTypes>();

    const handleClose = () => {
        setOpen(false);
    };

    const successText = iBeredskap
        ? "Prest er satt av beredskap, og har fått beskjed på SMS"
        : "Prest er satt i beredskap, og har fått beskjed på SMS";
    const warningText = iBeredskap
        ? "Prest er satt av beredskap, men har ikke har fått beskjed på SMS"
        : "Prest er satt i beredskap, men har ikke har fått beskjed på SMS";

    const handleToggleBeredskap = async () => {
        setLoading(true);

        try {
            const response = await httpPut(
                `api/Beredskapsomrader/${beredskapsomrade_id}/prest`,
                {
                    prestId: prest.id,
                    beredskapsomradeId: parseInt(beredskapsomrade_id),
                    iBeredskap: !iBeredskap,
                }
            );

            updateIBeredskap(prest.id, response.iBeredskap);

            response.notificationIsSent
                ? customToast.success(successText)
                : customToast.warning(warningText);
        } catch (e) {
            customToast.error(feilmeldinger.uventetFeil);
        } finally {
            setLoading(false);
            setOpen(false);
        }
    };

    return (
        <>
            <Checkbox
                id="toggleBeredskap"
                disabled={loading}
                color="primary"
                checked={iBeredskap}
                onChange={() => setOpen(true)}
                value={iBeredskap}
            />
            <DialogBox
                open={open}
                handleClick={handleToggleBeredskap}
                title=""
                buttonText="Ja"
                loading={loading}
                handleClose={handleClose}
            >
                <Typography variant="h6" className={classes.dialogContent}>
                    {`Er du sikker på at du vil sette ${prest.navn} ${
                        iBeredskap ? "av" : "i"
                    } beredskap?`}
                </Typography>
            </DialogBox>
        </>
    );
};
export default ToggleBeredskapDialogBox;
