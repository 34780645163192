import React, { useContext } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/combinedReducers";
import Telephonenumber from "../common/TelephoneNumber";
import {
    RoleContext,
    RoleContextType,
} from "../common/contexts/RoleContextProvider";
import UserContext, {
    UserContextType,
} from "../common/contexts/UserContextProvider";

const useStyles = makeStyles((theme: Theme) => ({
    userInfo: {
        padding: "2rem 2rem 2rem 2rem",
    },
    name: {
        marginBottom: "1rem",
        marginTop: "1rem",
    },
    body: {
        marginBottom: "0.5rem",
        fontSize: "1rem",
    },
    avatar: {
        marginBottom: "0.5rem",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    userInfoWrapper: {
        display: "flex",
    },
    userTitles: {
        marginRight: "1rem",
    },
}));
const UserInfo: React.FC = () => {
    const classes = useStyles();
    const { role } = useContext(RoleContext) as RoleContextType;
    const { user, userPhoto } = useContext(UserContext) as UserContextType;

    const email = useSelector((state: RootState) => {
        if (state.accountReducer.account !== undefined) {
            return state.accountReducer.account.username;
        } else {
            return "";
        }
    });

    return (
        <div className={classes.userInfo}>
            <Avatar
                className={classes.avatar}
                src={`data:image/jpeg;base64, ${userPhoto}`}
            />
            <Typography variant="h5" className={classes.name}>
                {user?.name}
            </Typography>
            <div className={classes.userInfoWrapper}>
                <div className={classes.userTitles}>
                    <Typography className={classes.body}>Din rolle:</Typography>
                    <Typography className={classes.body}>E-post:</Typography>
                    <Typography className={classes.body}>Telefon:</Typography>
                </div>
                <div>
                    <Typography className={classes.body}>{role}</Typography>
                    <Typography className={classes.body}>{email}</Typography>
                    <Typography className={classes.body}>
                        <Telephonenumber telephonenumber={user?.mobilephone} />
                    </Typography>
                </div>
            </div>
        </div>
    );
};
export default UserInfo;
