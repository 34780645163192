import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "../common/Card";
import Breadcrumb from "../common/Breadcrumb";
import { useParams } from "react-router-dom";
import { useLoader } from "../customHooks/useLoader";
import { httpGet } from "../../services/http/httpService";
import Beredskapsomrader from "../beredskapsomrader/Beredskapsomrader";
import InfoBox from "../common/InfoBox";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Proster from "../proster/Proster";
import BispedommeStatus from "./BispedommeStatus";
import { roles } from "../../utils/enum";
import ToggleNotification from "../notifications/ToggleNotification";
import TilbakeButton from "../common/TilbakeButton";
import Background from "../common/Background";
import HideForRole from "../common/HideForRole";
import { makeStyles, Theme } from "@material-ui/core/styles";
import UserContext, {
    UserContextType,
} from "../common/contexts/UserContextProvider";
import NotificationStatus from "../notifications/NotificationStatus";
import { useNotification } from "../notifications/useNotification";
import {
    RoleContext,
    RoleContextType,
} from "../common/contexts/RoleContextProvider";

const useStyles = makeStyles((theme: Theme) => ({
    topWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
    },
    statusDiv: {
        marginTop: "2rem",
        marginBottom: "3rem",
    },
}));

interface ParamTypes {
    bispedomme_id: string;
}

const Bispedomme: React.FC = () => {
    const { bispedomme_id } = useParams<ParamTypes>();

    const {
        data: bispedomme,
        loading,
        error,
    } = useLoader(() => {
        return httpGet(`api/Bispedommer/${bispedomme_id}`);
    });

    const { data: presterIBeredskap, error: presterIBeredskapError } =
        useLoader(() => {
            return httpGet(
                `api/Bispedommer/${bispedomme_id}/presterIBeredskap`
            );
        });
    const { user } = useContext(UserContext) as UserContextType;
    const { role } = useContext(RoleContext) as RoleContextType;

    const { notification, loadingNotification, handleToggleNotification } =
        useNotification(bispedomme_id, user, role, bispedomme?.varsel);

    const classes = useStyles();
    return (
        <Background>
            <HideForRole role={roles.PROST}>
                <Breadcrumb
                    root={{ name: "Bispedømmer", url: "/bispedommer" }}
                    paths={[
                        {
                            name: bispedomme?.navn,
                            url: `bispedommer/${bispedomme_id}`,
                        },
                    ]}
                />
            </HideForRole>

            <Card padding="1.5rem">
                {loading && <LinearProgress />}
                {error && (
                    <InfoBox
                        message={error.message}
                        icon={<ErrorOutlineIcon />}
                    />
                )}

                {bispedomme && (
                    <>
                        <HideForRole role={roles.PROST}>
                            <div className={classes.topWrapper}>
                                <TilbakeButton linkTo={"/bispedommer"} />

                                {!loading && (
                                    <ToggleNotification
                                        loading={loadingNotification}
                                        notification={notification}
                                        handleClick={handleToggleNotification}
                                    />
                                )}
                            </div>
                        </HideForRole>

                        <Typography variant="h4">
                            {bispedomme.navn} bispedømme
                        </Typography>

                        <div className={classes.statusDiv}>
                            <NotificationStatus notification={notification} />

                            <BispedommeStatus
                                beredskapsInfo={presterIBeredskap}
                                error={presterIBeredskapError}
                            />
                        </div>

                        <Beredskapsomrader
                            beredskapsomrader={bispedomme.beredskapsomrader}
                            presterIBeredskap={presterIBeredskap}
                        />

                        <HideForRole role={roles.PROST}>
                            <Proster bispedomme_id={bispedomme_id} />
                        </HideForRole>

                        <br />
                    </>
                )}
            </Card>
        </Background>
    );
};
export default Bispedomme;
