import { roles } from "../../utils/enum";
import { Beredskapsomrade } from "../prestSokeResultater/types";

export const getBreadcrumbPropsByRole = ( role: string, beredskapsomrade: Beredskapsomrade, bispedommeId: string, beredskapsomradeId: string) => {
    if (role === roles.PROST) {
        return {
            root: {
                name: beredskapsomrade.bispedommeNavn,
                url: `/bispedommer/${bispedommeId}`,
            },
            paths: [
                {
                    name: beredskapsomrade.navn,
                    url: `/bispedommer/${bispedommeId}/beredskapsomrade/${beredskapsomradeId}`,
                },
            ],
        };
    } else {
        return {
            root: { name: "Bispedømmer", url: "/bispedommer" },
            paths: [
                {
                    name: beredskapsomrade.bispedommeNavn,
                    url: `/bispedommer/${bispedommeId}`,
                },
                {
                    name: beredskapsomrade.navn,
                    url: `/bispedommer/${bispedommeId}/beredskapsomrader/${beredskapsomradeId}`,
                },
            ],
        };
    }
};
