import { Beredskapsomrade } from "../../components/prestSokeResultater/types";

export const HENT_BISPEDOMMER_START = "HENT_BISPEDOMMER_START";
export const HENT_BISPEDOMMER_SUCCESS = "HENT_BISPEDOMMER_SUCCESS";
export const HENT_BISPEDOMMER_FAIL = "HENT_BISPEDOMMER_FAIL";

export interface BispedommeState {
    loading: boolean;
    error: Error | null;
    bispedommer: Bispedomme[];
}

interface BispedommeAction {
    type: string;
    payload?: Bispedomme[] | Error;
}

export interface Bispedomme {
    id: number;
    navn: string;
    beredskapsomrader: Beredskapsomrade[];
}

export type BispedommeActionTypes = BispedommeAction;
