import React, { useContext, useState } from "react";
import DialogBox from "../common/DialogBox";
import Container from "@material-ui/core/Container";
import { Prest } from "../../store/prester/types";
import { Typography } from "@material-ui/core";
import { httpDelete } from "../../services/http/httpService";
import {
    BeredskapsomradeContext,
    BeredskapsContextType,
} from "../beredskapsomrade/contexts/beredskapsomradeContext";
import { feilmeldinger } from "../../utils/constants";

interface Props {
    omradeId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    prest: Prest;
}

const RemovePrestDialogBox: React.FC<Props> = ({
    omradeId,
    open,
    setOpen,
    prest,
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { removePrest } = useContext(
        BeredskapsomradeContext
    ) as BeredskapsContextType;

    const handleClose = () => {
        setOpen(false);
        setError(null);
    };

    const handleRemovePrest = async () => {
        setLoading(true);
        try {
            await httpDelete(
                `api/Beredskapsomrader/${omradeId}/prest/${prest.id}`
            );

            removePrest(prest.id);
            setError(null);
            setOpen(false);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <DialogBox
                handleClick={handleRemovePrest}
                title=""
                buttonText={prest.iBeredskap ? "" : "Fjern"}
                cancelButtonText={prest.iBeredskap ? "OK" : "Avbryt"}
                open={open}
                handleClose={handleClose}
                loading={loading}
                error={error ? feilmeldinger.uventetFeilProvPaNytt : ""}
            >
                <Container>
                    {prest.iBeredskap ? (
                        <Typography variant="h6">
                            Du kan ikke fjerne en prest som er i aktiv
                            beredskap. Sett {prest.navn} av beredskap først, og
                            så kan du slette presten fra beredskapsområdet.
                        </Typography>
                    ) : (
                        <Typography variant="h6">
                            Er du sikker på at du vil fjerne {prest.navn} fra
                            beredskapsområdet?
                        </Typography>
                    )}
                </Container>
            </DialogBox>
        </>
    );
};
export default RemovePrestDialogBox;
