import React, { useState } from "react";
import DialogBox from "../common/DialogBox";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import { httpDelete } from "../../services/http/httpService";
import { feilmeldinger } from "../../utils/constants";
import { User } from "../types";

interface Props {
    omradeId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    prost: User;
    removeProst: (id: number) => void;
}

const RemoveProstDialogBox: React.FC<Props> = ({
    omradeId,
    open,
    setOpen,
    prost,
    removeProst,
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setError(null);
    };

    const handleRemoveProst = async () => {
        setLoading(true);
        try {
            await httpDelete(`api/Bispedommer/${omradeId}/prost/${prost.id}`);
            removeProst(prost.id);
            setError(null);
            setOpen(false);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <DialogBox
                handleClick={handleRemoveProst}
                title=""
                buttonText="Fjern"
                open={open}
                handleClose={handleClose}
                loading={loading}
                error={error ? feilmeldinger.uventetFeilProvPaNytt : ""}
            >
                <Container>
                    <Typography variant="h6">
                        Er du sikker på at du vil fjerne {prost.name} fra
                        bispedømmet?
                    </Typography>
                </Container>
            </DialogBox>
        </>
    );
};
export default RemoveProstDialogBox;
