import React from "react";
import InfoBox from "../common/InfoBox";
import { feilmeldinger } from "../../utils/constants";
import { signout } from "../../authentication/authModule";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Typography from "@material-ui/core/Typography";

interface Props {
    errorMessage?: string;
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: "2rem",
        textAlign: "center",
    },
    errorCodeMessage: {
        margin: "1rem",
    },
}));
const Unauthorized: React.FC<Props> = ({ errorMessage }) => {
    const classes = useStyles();
    return (
        <Container className={classes.root}>
            <InfoBox
                message={feilmeldinger.http[401]}
                icon={<ErrorOutlineIcon />}
            >
                <Typography
                    variant="body1"
                    className={classes.errorCodeMessage}
                >
                    Feil av type: 401.
                </Typography>
                <Button startIcon={<ExitToAppIcon />} onClick={signout}>
                    Logg ut
                </Button>
            </InfoBox>
        </Container>
    );
};

export default Unauthorized;
