import {
    PublicClientApplication,
    InteractionRequiredAuthError,
} from "@azure/msal-browser";

import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

const loginRequest = {
    scopes: ["openid", "profile", window.config.apiScope],
};

export const handleRedirectPromise = () => {
    return msalInstance.handleRedirectPromise();
};

export const signout = () => {
    msalInstance.logout();
};

export const signoutAndRedirect = () => {
    msalInstance.logout().then(() => {
        msalInstance.loginRedirect(loginRequest);
    });
};

export const login = () => {
    msalInstance.loginRedirect(loginRequest);
};

export const getTokenSilent = async () => {
    var account = getAccount();
    if (account === undefined) {
        console.log(
            "[getTokenSilent] Account is undefined. Cannot do msalInstance.acquireTokenSilent without account"
        );
        return "";
    }
    var request = {
        scopes: [window.config.apiScope],
        account,
    };
    try {
        const res = await msalInstance.acquireTokenSilent(request);
        return res.accessToken;
    } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
            console.log("acquiring token using redirect");
            msalInstance.acquireTokenRedirect(request).catch(console.error);
        } else {
            console.log(e);
        }
    }

    return "";
};

const getAccount = () => {
    const currentAccounts = msalInstance.getAllAccounts();
    if (currentAccounts === null) {
        console.log("No accounts detected");
        return undefined;
    }

    if (currentAccounts.length > 1) {
        console.log(
            "Multiple accounts detected, need to add choose account code."
        );
        return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
        return currentAccounts[0];
    }
};
