import React, { useContext } from "react";
import { RoleType } from "../../utils/types";
import {
    RoleContextType,
    RoleContext,
} from "../common/contexts/RoleContextProvider";

interface Props {
    role?: RoleType;
    roles?: RoleType[];
    children: React.ReactNode;
}

const HideForRole: React.FC<Props> = ({ role, roles, children }) => {
    const { role: currentUserRole } = useContext(
        RoleContext
    ) as RoleContextType;

    var condition = false;
    if (role) {
        condition = currentUserRole !== role;
    } else if (roles) {
        condition = !roles.includes(currentUserRole);
    }

    return <>{condition && children}</>;
};
export default HideForRole;
