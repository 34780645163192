import React, { useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import { Container } from "@material-ui/core";
import KirkeLogo from "../settings/KirkeLogo";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) => ({
    menuButtonInDrawerContainer: {
        display: "flex",

        [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-end",
        },
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginTop: "0.5rem",
    },
    menuButtonHeader: {
        display: "flex",
        alignItems: "center",
        "&:hover": {
            cursor: "pointer",
        },
    },
    menuButtonInDrawerWrapper: {
        [theme.breakpoints.up("lg")]: {
            marginRight: "2rem",
        },
        "&:hover": {
            cursor: "pointer",
        },
        display: "flex",
        alignItems: "center",
    },
}));

interface Props {
    children: React.ReactNode;
}

const Drawer: React.FC<Props> = ({ children }) => {
    const [state, setState] = useState({
        isOpen: false,
    });

    const handleClickOpen = () => {
        setState({ ...state, isOpen: !state.isOpen });
    };

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => {
        if (
            event &&
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        setState({ ...state, isOpen: open });
    };
    const classes = useStyles();
    const theme = useTheme();
    const isOnMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

    const Logo = () => {
        return isOnMobileDevice ? <></> : <KirkeLogo />;
    };

    return (
        <>
            <div className={classes.menuButtonHeader} onClick={handleClickOpen}>
                {isOnMobileDevice ? (
                    <IconButton>
                        <MenuIcon />
                    </IconButton>
                ) : (
                    <Button size="large" endIcon={<MenuIcon />} variant="text">
                        Meny
                    </Button>
                )}
            </div>
            <SwipeableDrawer
                open={state.isOpen}
                onOpen={toggleDrawer(true)}
                onClose={toggleDrawer(false)}
                anchor={isOnMobileDevice ? "left" : "top"}
            >
                <Container className={classes.menuButtonInDrawerContainer}>
                    <Logo />
                    <div
                        className={classes.menuButtonInDrawerWrapper}
                        onClick={toggleDrawer(false)}
                    >
                        <IconButton>
                            {isOnMobileDevice ? (
                                <ChevronLeftIcon />
                            ) : (
                                <CloseIcon />
                            )}
                        </IconButton>
                    </div>
                </Container>
                {children}
            </SwipeableDrawer>
        </>
    );
};

export default Drawer;
