import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

interface Props {
    message: string;
    icon: React.ReactNode;
    children?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
    root: {
        padding: "2rem",
    },
    icon: {
        textAlign: "center",
    },
    message: {
        textAlign: "center",
    },
}));

const InfoBox: React.FC<Props> = ({ message, icon, children }) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <p className={classes.icon}>{icon}</p>
                <Typography variant="h6" className={classes.message}>
                    {message}
                </Typography>

                {children}
            </CardContent>
        </Card>
    );
};

export default InfoBox;
