import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { Column } from "./types";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(() => ({
    root: {
        fontWeight: 700,
    },
}));

interface Props {
    columns: Column[];
}

const TableHeader: React.FC<Props> = ({ columns }) => {
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow>
                {columns.map((header, i) => (
                    <Hidden key={i} smDown={header.hideOnMobile}>
                        <TableCell key={header.path} className={classes.root}>
                            {header.content && header.content()}
                            {header.label}
                        </TableCell>
                    </Hidden>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
