import { useState, useEffect } from "react";

export interface Loader<T> {
    data?: T;
    loading: boolean;
    error?: Error;
    reload(): void;
}

type DependencyList = [];

export function useLoader<T>(
    loadingFunction: () => Promise<T>,
    deps: DependencyList = []
): Loader<T> {
    const [data, setData] = useState<T | undefined>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | undefined>();

    async function reload() {
        setLoading(true);
        setError(undefined);
        setData(undefined);
        try {
            setData(await loadingFunction());
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    return { data, loading, error, reload };
}
