import React, { useState, createContext, useEffect, useContext } from "react";
import { httpGet } from "../../../services/http/httpService";
import { useLoader } from "../../customHooks/useLoader";
import LinearProgress from "@material-ui/core/LinearProgress";
import { RoleContext, RoleContextType } from "./RoleContextProvider";
import { roles } from "../../../utils/enum";

export type UserContextType = {
    user: UserType;
    setUser: (user: UserType) => void;
    userPhoto: string;
    setPhoto: (img: string) => void;
    loading: boolean;
    error: Error;
};

export type UserType = {
    id: number;
    azureAdId: string;
    name: string;
    mobilephone: string;
    email: string;
    bispedommeIds: number[];
};

interface Props {
    children: React.ReactNode;
}
export const UserContext = createContext<UserContextType | {}>({});

export const UserContextProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = useState<UserType>();
    const [userPhoto, setPhoto] = useState<string>();

    const { role } = useContext(RoleContext) as RoleContextType;

    useEffect(() => {
        (async () => {
            try {
                const userPhotoResponse = await httpGet("api/Users/user/photo");
                setPhoto(userPhotoResponse);
            } catch (e) {
                console.error(
                    "Error in api/Users/user/photo response from api",
                    e
                );
            }
        })();
    }, []);

    const { loading, error } = useLoader(async () => {
        if (role && role !== roles.SECURITAS) {
            const userResponse = await httpGet(`api/Users/user`);
            setUser(userResponse);
        }
    });

    return (
        <UserContext.Provider
            value={{ user, setUser, userPhoto, setPhoto, error }}
        >
            {loading ? <LinearProgress /> : children}
        </UserContext.Provider>
    );
};

export default UserContext;
