import React, { useEffect, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./Header";
import { getRoleByPriority } from "../../utils/roleProvider";
import { getTokenSilent } from "../../authentication/authModule";
import { TokenType } from "../../utils/types";
import ServiceWorkerAlert from "../serviceWorker/serviceWorkerAlert";
import Routes from "./Routes";
import {
    RoleContext,
    RoleContextType,
} from "../common/contexts/RoleContextProvider";
import { UserContextProvider } from "../common/contexts/UserContextProvider";

const App: React.FC = () => {
    const { setRole, role, setRoleList, roleList } = useContext(
        RoleContext
    ) as RoleContextType;
    useEffect(() => {
        async function getToken() {
            const token = await getTokenSilent();
            var tokenModel: TokenType = JSON.parse(atob(token.split(".")[1]));
            setRoleList(tokenModel.roles);
            setRole(getRoleByPriority(tokenModel));
        }

        getToken();
    }, [setRole, setRoleList]);

    return (
      <BrowserRouter>
          {role && (
              <UserContextProvider>
                  <Header />
                  <ServiceWorkerAlert />
                  <Routes role={role} roleList={roleList} />
              </UserContextProvider>
          )}
      </BrowserRouter>
    );
};

export default App;
