import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
    formControll: {
        "& .MuiFormControlLabel-label": {
            fontSize: "14px",
        },
    },
}));

interface Props {
    notification: boolean;
    loading: boolean;
    handleClick: () => void;
}

const ToggleNotification: React.FC<Props> = ({
    notification,
    loading,
    handleClick,
}) => {
    const classes = useStyles();
    return (
        <FormControlLabel
            control={
                <Switch
                    disabled={loading}
                    color="primary"
                    checked={notification}
                    onChange={handleClick}
                    value={notification}
                />
            }
            className={classes.formControll}
            label={notification ? "VARSLING PÅ" : "VARSLING AV"}
            labelPlacement={"start"}
        />
    );
};
export default ToggleNotification;
