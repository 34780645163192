import React from "react";
import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";

interface Kommune {
    navn: string;
}

export const visKommuner = (
    kommuner: Array<Kommune>,
    kommuneLimit = 3,
    type: Variant = "subtitle1"
) => {
    let kommuneTekst = kommuner
        .slice(0, kommuneLimit)
        .map((kommune) => kommune.navn)
        .join(", ");

    if (kommuner.length > kommuneLimit) {
        kommuneTekst += " ...";
    }
    return <Typography variant={type}>{kommuneTekst}</Typography>;
};
