import {
    BispedommeState,
    BispedommeActionTypes,
    HENT_BISPEDOMMER_SUCCESS,
    HENT_BISPEDOMMER_START,
    HENT_BISPEDOMMER_FAIL,
} from "./types";

export const initialState: BispedommeState = {
    loading: false,
    error: null,
    bispedommer: [],
};

export const bispedommerReducer = (
    state = initialState,
    action: BispedommeActionTypes
) => {
    switch (action.type) {
        case HENT_BISPEDOMMER_START:
            return {
                ...state,
                loading: true,
            };
        case HENT_BISPEDOMMER_SUCCESS:
            return {
                ...state,
                loading: false,
                bispedommer: action.payload,
            };
        case HENT_BISPEDOMMER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
