export const isIOsInStandaloneMode = () => {
    const windowNavigator: any = window.navigator;

    const userAgent = windowNavigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(userAgent)) {
        return "standalone" in windowNavigator && windowNavigator.standalone;
    }
    return false;
};
