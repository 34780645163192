import React from "react";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { PresterIBeredskap } from "../types";
import { feilmeldinger } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "1rem",
    },
    errorContent: {
        display: "flex",
        alignItems: "flex-start",
        borderRadius: "0.25rem",
        maxWidth: 390,
        padding: "0.5rem",
        margin: 0,
        background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9100;",
    },
    content: {
        display: "flex",
        padding: 0,
    },
    okicon: {
        marginRight: "0.5rem",
        fill: "green",
    },
    warningicon: {
        marginRight: "0.5rem",
        fill: "#F4D150",
    },
}));

interface Props {
    beredskapsInfo: PresterIBeredskap[];
    error: Error | undefined;
}

const BispedommeStatus: React.FC<Props> = ({ beredskapsInfo, error }) => {
    const antallOmrader = beredskapsInfo?.length;

    const antallOmraderMedUgyldigStatus = beredskapsInfo?.filter(
        (omrade) => omrade.antallPresterIBeredskap !== 1
    ).length;

    const classes = useStyles();
    return (
        <div className={classes.root}>
            {beredskapsInfo && (
                <>
                    {antallOmraderMedUgyldigStatus > 0 ? (
                        <Container className={classes.errorContent}>
                            <ErrorIcon className={classes.warningicon} />
                            <Typography>
                                Det er avvik i {antallOmraderMedUgyldigStatus}{" "}
                                av {antallOmrader} beredskapsområder.
                            </Typography>
                        </Container>
                    ) : (
                        <Container className={classes.content}>
                            <CheckCircleIcon className={classes.okicon} />
                            <Typography>
                                Alle områder har prest i beredskap.
                            </Typography>
                        </Container>
                    )}
                </>
            )}
            {error && (
                <Container className={classes.errorContent}>
                    <ErrorIcon className={classes.warningicon} />
                    <Typography>{feilmeldinger.uventetFeil}</Typography>
                </Container>
            )}
        </div>
    );
};
export default BispedommeStatus;
