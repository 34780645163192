import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: "2rem",
    },
    cardWrapper: {
        margin: 0,
        width: "100%",
        height: 365,
    },
}));

interface Props {
    children: React.ReactNode;
}

const Background: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <Container className={classes.root}>
            <Grid item lg={12} sm={12} md={12} className={classes.cardWrapper}>
                {children}
                <br />
            </Grid>
        </Container>
    );
};
export default Background;
