import {
    AuthState,
    AuthActionTypes,
    AUTHENTICATE_FAIL,
    AUTHENTICATE_START,
    AUTHENTICATE_SUCCESS,
} from "./types";

export const initialState: AuthState = {
    authenticated: null,
    loading: false,
    error: null,
};

export const authReducer = (state = initialState, action: AuthActionTypes) => {
    switch (action.type) {
        case AUTHENTICATE_START:
            return {
                ...state,
                loading: true,
            };
        case AUTHENTICATE_SUCCESS:
            return {
                ...state,
                loading: false,
                authenticated: true,
            };
        case AUTHENTICATE_FAIL:
            return {
                ...state,
                loading: false,
                authenticated: false,
                error: action.payload?.error,
            };
        default:
            return state;
    }
};
