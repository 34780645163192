import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { roles } from "../../utils/enum";
import Beredskapsomrade from "../beredskapsomrade/Beredskapsomrade";
import Bispedomme from "../bispedomme/Bispedomme";
import LinearProgress from "@material-ui/core/LinearProgress";
import { BeredskapsomradeProvider } from "../beredskapsomrade/contexts/beredskapsomradeContext";
import { RoleType } from "../../utils/types";
import PresterIBeredskapsomradet from "../prester/PresterIBeredskapsomradet";
import KontaktpersonerIBeredskapsomradet from "../proster/KontaktpersonerIBeredskapsomradet";

const SecuritasPage = lazy(
    () =>
        import(/* webpackChunkName: "SecuritasPage" */ "../Pages/SecuritasPage")
);
const BeredskapsansvarligPage = lazy(
    () =>
        import(
            /* webpackChunkName: "BeredskapsansvarligPage" */ "../Pages/BeredskapsansvarligPage"
        )
);
const PrestPage = lazy(
    () => import(/* webpackChunkName: "PrestPage" */ "../Pages/PrestPage")
);
const NotFound = lazy(
    () => import(/* webpackChunkName: "NotFound" */ "../Pages/NotFound")
);
const Unauthorized = lazy(
    () => import(/* webpackChunkName: "Unauthorized" */ "../Pages/Unauthorized")
);

const ProstPage = lazy(
    () => import(/* webpackChunkName:"ProstPage" */ "../Pages/ProstPage")
);

interface Props {
    role: RoleType;
    roleList: RoleType[];
}

const Routes: React.FC<Props> = ({ role, roleList }) => {
    const { ADMIN, BEREDSKAPSANSVARLIG, SECURITAS, PREST, PROST } = roles;
    const getPaths = (rolle?: RoleType) => {
        switch (rolle) {
            case BEREDSKAPSANSVARLIG:
            case ADMIN:
            case PROST:
                return "/bispedommer";
            case SECURITAS:
                return "/securitas";
            case PREST:
                return "/prest";
            default:
                return "/not-found";
        }
    };

    return (
        <Switch>
            <Redirect from="/" exact to={getPaths(role)} />
            {(role === BEREDSKAPSANSVARLIG ||
                role === ADMIN ||
                role === PROST) && (
                <Route
                    exact
                    path={
                        "/bispedommer/:bispedomme_id/beredskapsomrader/:beredskapsomrade_id"
                    }
                >
                    <BeredskapsomradeProvider>
                        <Beredskapsomrade />
                    </BeredskapsomradeProvider>
                </Route>
            )}
            {(role === SECURITAS || role === ADMIN) && (
                <Route exact path="/securitas">
                    <Suspense fallback={<LinearProgress />}>
                        <SecuritasPage />
                    </Suspense>
                </Route>
            )}
            {(role === BEREDSKAPSANSVARLIG ||
                role === PROST ||
                role === ADMIN) && (
                <Route exact path={"/bispedommer/:bispedomme_id"}>
                    <Bispedomme />
                </Route>
            )}
            {(role === PREST ||
                role === ADMIN ||
                (role === PROST && roleList.includes(PREST))) && (
                <Route exact path="/prest">
                    <Suspense fallback={<LinearProgress />}>
                        <PrestPage />
                    </Suspense>
                </Route>
            )}
            {role === PREST && (
                <Route
                    exact
                    path="/prest/beredskapsomrader/:beredskapsomrade_id"
                >
                    <PresterIBeredskapsomradet />
                </Route>
            )}
            {role === PREST && (
                <Route
                    exact
                    path="/prest/beredskapsomrader/:beredskapsomrade_id/kontaktpersoner"
                >
                    <KontaktpersonerIBeredskapsomradet />
                </Route>
            )}
            {(role === BEREDSKAPSANSVARLIG || role === ADMIN) && (
                <Route exact path="/bispedommer">
                    <Suspense fallback={<LinearProgress />}>
                        <BeredskapsansvarligPage />
                    </Suspense>
                </Route>
            )}
            {role === PROST && (
                <Route exact path="/bispedommer">
                    <Suspense fallback={<LinearProgress />}>
                        <ProstPage />
                    </Suspense>
                </Route>
            )}

            <Route path="/unauthorized">
                <Suspense fallback={<LinearProgress />}>
                    <Unauthorized />
                </Suspense>
            </Route>
            <Route path="/not-found">
                <Suspense fallback={<LinearProgress />}>
                    <NotFound />
                </Suspense>
            </Route>
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
