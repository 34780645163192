import React, { useState, createContext } from "react";
import { RoleType } from "../../../utils/types";

export type RoleContextType = {
    role: RoleType;
    roleList: RoleType[];
    setRole: (role: RoleType) => void;
    setRoleList: (roleList: RoleType[]) => void;
};
interface Props {
    children: React.ReactNode;
}
export const RoleContext = createContext<RoleContextType | {}>({});

export const RoleContextProvider: React.FC<Props> = ({ children }) => {
    const [role, setRole] = useState<RoleType | "Unknown">();
    const [roleList, setRoleList] = useState<RoleType[]>([]);

    return (
        <RoleContext.Provider value={{ setRole, role, roleList, setRoleList }}>
            {children}
        </RoleContext.Provider>
    );
};

export default RoleContext;
