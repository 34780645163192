export const AUTHENTICATE_START = "AUTHENTICATE_START";
export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_FAIL = "AUTHENTICATE_FAIL";

export interface AuthState {
    authenticated: boolean | null;
    loading: boolean;
    error: any;
}

interface AuthAction {
    type: string;
    payload?: any;
}

export type AuthActionTypes = AuthAction;
