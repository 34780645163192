import React, { useContext, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {
    RoleContext,
    RoleContextType,
} from "../common/contexts/RoleContextProvider";
import { roles } from "../../utils/enum";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    radioButtonGroup: {
        marginLeft: "4.3rem",
    },
}));

const RoleViews: React.FC = () => {
    const [rolle, setRolle] = useState("prest");

    const classes = useStyles();
    const history = useHistory();
    const { roleList, setRole } = useContext(RoleContext) as RoleContextType;
    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRolle(event.target.value);
    };
    const handleRedirect = (role: any) => {
        if (role === roles.PROST) {
            setRole(roles.PROST);
            history.push("/bispedommer");
        }

        if (role === roles.PREST) {
            setRole(roles.PREST);
            history.push("/prest");
        }
    };
    return (
        <FormControl>
            <RadioGroup
                className={classes.radioButtonGroup}
                aria-label="rolle"
                name="rolle"
                value={rolle}
                onChange={handleChangeRadio}
            >
                {roleList.map((role, i) => (
                    <div onClick={() => handleRedirect(role)} key={i}>
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            label={role}
                            value={role}
                        />
                    </div>
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RoleViews;
