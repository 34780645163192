import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttonText: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
    tilbake: {
        marginBottom: "2rem",
    },
    link: { textDecoration: "none" },
}));

interface Props {
    linkTo: string;
}

const TilbakeButton: React.FC<Props> = ({ linkTo }) => {
    const classes = useStyles();

    return (
        <div className={classes.tilbake}>
            <Link to={linkTo} className={classes.link}>
                <Button startIcon={<ArrowBackIcon />}>
                    <div className={classes.buttonText}>Tilbake</div>
                </Button>
            </Link>
        </div>
    );
};
export default TilbakeButton;
