import React, { useState } from "react";
import DialogBox from "../common/DialogBox";
import Container from "@material-ui/core/Container";
import { httpPut } from "../../services/http/httpService";
import { feilmeldinger } from "../../utils/constants";
import { User } from "../types";
import ProstVarslingerListe, {
    ProstVarselResponse,
} from "./ProstVarslingerListe";
import { Typography } from "@material-ui/core";
import { customToast } from "../../services/varslinger/varslingsService";

interface Props {
    bispedommeId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    prost: User;
}

const ProstVarslingerDialogBox: React.FC<Props> = ({
    bispedommeId,
    open,
    setOpen,
    prost,
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState<ProstVarselResponse[] | null>(null);

    const handleClose = () => {
        setOpen(false);
        setError(null);
    };

    const handleUpdateVarsler = async () => {
        setLoading(true);
        try {
            await httpPut(
                `api/Bispedommer/${bispedommeId}/proster/${prost.id}/varsel`,
               {
                   data:data,
                   userId: prost.id,
                   BispedommeId: bispedommeId
               }
            );
            
            setError(null);
            setOpen(false);
            customToast.success(`Varslinger er endret for ${prost.name}`);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <DialogBox
                handleClick={handleUpdateVarsler}
                title={""}
                buttonText="Bekreft"
                cancelButtonText="Nei, avbryt"
                open={open}
                handleClose={handleClose}
                loading={loading}
                error={error ? feilmeldinger.uventetFeilProvPaNytt : ""}
            >
                <Container>
                    <Typography variant="h6">
                        Administrer varslinger for {prost.name}
                    </Typography>
                    <ProstVarslingerListe
                        data={data}
                        setData={setData}
                        bispedommeId={bispedommeId}
                        prost={prost}
                    />
                </Container>
            </DialogBox>
        </>
    );
};
export default ProstVarslingerDialogBox;
