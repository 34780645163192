import React, { useState, createContext } from "react";
import { Prest } from "../../../store/prester/types";
import { customToast } from "../../../services/varslinger/varslingsService";

export type BeredskapsContextType = {
    data: any;
    updateIBeredskap: (prestId: number, iBeredskap: boolean) => void;
    removePrest: (prestId: number) => void;
    setData: (res: any) => void;
    leggTilPrest: (prest: Prest) => void;
    getPrestIBeredskap: () => Prest[];
};

export const BeredskapsomradeContext = createContext<
    BeredskapsContextType | {}
>({});

interface Props {
    children: React.ReactNode;
}

export const BeredskapsomradeProvider: React.FC<Props> = ({ children }) => {
    const [data, setData] = useState({
        beredskapsomrade: {
            id: 0,
            bispedommeNavn: "",
            navn: "",
            kommuner: [
                {
                    navn: "",
                    kommuneNr: "",
                },
            ],
            varsel: undefined,
        },
        prester: [] as Prest[],
    });

    const removePrest = (prestId: number) => {
        setData((previousState) => ({
            ...previousState,
            prester: previousState.prester.filter(
                (p: Prest) => p.id !== prestId
            ),
        }));
        customToast.success("Prest ble fjernet fra området.");
    };

    const leggTilPrest = (prest: Prest) => {
        setData((previousState) => ({
            ...previousState,
            prester: [...previousState.prester, prest],
        }));
        customToast.success("Prest ble lagt til i området.");
    };

    const updateIBeredskap = (prestId: number, iBeredskap: boolean) => {
        const oppdatertePrester = data.prester.map((prest) =>
            prest.id === prestId ? { ...prest, iBeredskap } : prest
        );
        setData((previousState) => ({
            ...previousState,
            prester: oppdatertePrester,
        }));
    };

    const getPrestIBeredskap = () => {
        return data.prester.filter((p) => p.iBeredskap);
    };

    return (
        <BeredskapsomradeContext.Provider
            value={{
                data,
                setData,
                updateIBeredskap,
                removePrest,
                leggTilPrest,
                getPrestIBeredskap,
            }}
        >
            {children}
        </BeredskapsomradeContext.Provider>
    );
};
