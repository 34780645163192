export class HttpConfig {
    baseUrl: string;
    headers: {};

    constructor(token: string) {
        this.baseUrl = window.config.apiUrl;

        this.headers = {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
        };
    }
}

export const baseUrl = window.config.apiUrl;

export const defaultHttpConfig = {
    baseUrl,
    headers: {
        "Content-Type": "application/json",
    },
};
