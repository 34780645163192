import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { signout } from "../../authentication/authModule";
import List from "@material-ui/core/List";

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        paddingLeft: "2rem",
        marginBottom: "1rem",
    },
    listIcon: {
        minWidth: "2.5rem",
    },
}));

export const LogoutListItem: React.FC = () => {
    const classes = useStyles();

    return (
        <ListItem button className={classes.listItem} onClick={signout}>
            <ListItemIcon className={classes.listIcon}>
                <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"Logg ut"} />
        </ListItem>
    );
};
const Logout: React.FC = () => {
    return (
        <List>
            <LogoutListItem />
        </List>
    );
};
export default Logout;
